import { useNavMenu } from "./useNavMenu";
import { Box, Center, Collapse, Flex, Image, SimpleGrid, useDisclosure } from "@chakra-ui/react";
import * as React from "react";
import { FaChevronDown } from "react-icons/fa";
import { Link } from "./_data";
import { NavLink } from "./NavLink";
import { NavMenu } from "./NavMenu";
import { SubmenuItem as DesktopMenuItem } from "./SubmenuItem";
import { navigate } from "gatsby";

interface SubmenuProps {
  link: Link;
}

const DesktopSubmenu = (props: SubmenuProps) => {
  const { link } = props;
  const { isOpen, getMenuProps, getTriggerProps } = useNavMenu();
  return (
    <>
      <NavLink.Desktop display="flex" alignItems="center" as="button" type="button" px="4" {...getTriggerProps()}>
        <Box>{link.label}</Box>
        <Box marginStart="2" as={FaChevronDown} fontSize="xs" />
      </NavLink.Desktop>

      <NavMenu {...getMenuProps()} animate={isOpen ? "open" : "closed"}>
        <Box maxW="7xl" mx="auto" px="8">
          <SimpleGrid spacing="10" columns={2}>
            {link.children?.map((item, idx) => (
              <DesktopMenuItem key={idx} title={item.label} href={item.href} icon={item.icon}>
                {item.description}
              </DesktopMenuItem>
            ))}
          </SimpleGrid>
        </Box>
      </NavMenu>
    </>
  );
};

const MobileSubMenu = (props: SubmenuProps) => {
  const { link } = props;
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <NavLink.Mobile as="button" textAlign="start" type="button" cursor="pointer" onClick={onToggle} paddingEnd="4">
        <Box flex="1">{link.label}</Box>
        <Box as={FaChevronDown} transform={`rotate(${isOpen ? "180deg" : "0deg"})`} />
      </NavLink.Mobile>
      <Collapse in={isOpen}>
        {link.children?.map((item, idx) => (
          <Flex key={`${item.label} at ${idx}`} borderBottomWidth="1px">
            <NavLink.Mobile key={idx} onClick={() => navigate(item.href)} mr="0">
              {item.label}
            </NavLink.Mobile>
            {item.mobileIcon}
          </Flex>
        ))}
      </Collapse>
    </Box>
  );
};

export const Submenu = {
  Mobile: MobileSubMenu,
  Desktop: DesktopSubmenu,
};
