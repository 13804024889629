import React, { ReactElement } from "react";

export function Treasure(): ReactElement {
  return (
    <svg height="80pt" viewBox="0 150 512 512" width="40pt" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m503.464844 102.398438v8.535156h-494.929688v-8.535156c-.015625-24.898438 9.871094-48.78125 27.476563-66.386719s41.488281-27.492188 66.386719-27.476563h307.203124c24.898438-.015625 48.78125 9.871094 66.386719 27.476563s27.492188 41.488281 27.476563 66.386719zm0 0"
        fill="#795548"
      />
      <path
        d="m503.464844 110.933594-59.730469 136.53125h-375.46875l-59.730469-136.53125 234.410156-12.117188 12.796876-.683594zm0 0"
        fill="#4e342e"
      />
      <path
        d="m477.867188 110.933594-59.734376 136.53125h-349.867187l-59.730469-136.53125 234.410156-12.117188zm0 0"
        fill="#795548"
      />
      <path
        d="m104.269531 249.070312 27.734375-21.914062 16.792969 12.460938 6.9375 24.113281zm0 0"
        fill="#802f34"
      />
      <path
        d="m502.273438 265.644531-14.847657 221.863281c-.585937 8.957032-8.007812 15.933594-16.984375 15.957032h-428.882812c-8.976563-.023438-16.398438-7-16.984375-15.957032l-14.847657-221.863281c-.289062-4.714843 1.375-9.335937 4.609376-12.777343 3.230468-3.441407 7.738281-5.394532 12.457031-5.402344h458.414062c4.71875.007812 9.226563 1.960937 12.457031 5.402344 3.234376 3.441406 4.898438 8.0625 4.609376 12.777343zm0 0"
        fill="#f57c00"
      />
      <path
        d="m477.867188 265.644531-14.847657 221.863281c-.585937 8.957032-8.007812 15.933594-16.980469 15.957032h-404.480468c-8.976563-.023438-16.398438-7-16.984375-15.957032l-14.847657-221.863281c-.289062-4.714843 1.375-9.335937 4.609376-12.777343 3.230468-3.441407 7.738281-5.394532 12.457031-5.402344h434.007812c4.71875.007812 9.230469 1.960937 12.460938 5.402344 3.230469 3.441406 4.898437 8.0625 4.605469 12.777343zm0 0"
        fill="#ff9801"
      />
      <path
        d="m213.691406 258.492188-8.378906-47.789063-14.164062-33.976563c18.496093-4.066406 37.378906-6.097656 56.316406-6.058593 11.183594-.027344 22.355468.660156 33.453125 2.046875l23.894531 85.777344zm0 0"
        fill="#fec108"
      />
      <path
        d="m189.269531 219.988281-5.121093 27.476563h-41.300782l-9.128906-7.761719v-.085937l5.375-28.929688 27.816406-9.8125 22.273438 19.113281zm0 0"
        fill="#f44335"
      />
      <path d="m317.867188 179.710938.085937-.339844.9375.597656zm0 0" fill="#804537" />
      <path
        d="m273.066406 298.667969c0 9.425781-7.640625 17.066406-17.066406 17.066406s-17.066406-7.640625-17.066406-17.066406 7.640625-17.066407 17.066406-17.066407 17.066406 7.640626 17.066406 17.066407zm0 0"
        fill="#607d8b"
      />
      <path
        d="m204.800781 247.464844v68.269531c0 28.277344 22.921875 51.199219 51.199219 51.199219s51.199219-22.921875 51.199219-51.199219v-68.269531zm51.199219 68.269531c-9.425781 0-17.066406-7.640625-17.066406-17.066406s7.640625-17.066407 17.066406-17.066407 17.066406 7.640626 17.066406 17.066407c-.027344 9.414062-7.652344 17.039062-17.066406 17.066406zm0 0"
        fill="#fec108"
      />
      <path
        d="m217.941406 349.867188h-151.296875l-2.816406-42.070313c-.164063-2.363281.660156-4.691406 2.277344-6.421875 1.621093-1.734375 3.886719-2.714844 6.257812-2.707031h132.4375v17.066406c-.027343 12.617187 4.660157 24.789063 13.140625 34.132813zm0 0"
        fill="#795548"
      />
      <path
        d="m445.355469 349.867188-3.414063 51.199218h-371.882812l-3.414063-51.199218h151.296875c9.671875 10.855468 23.519532 17.066406 38.058594 17.066406s28.386719-6.210938 38.058594-17.066406zm0 0"
        fill="#bfa885"
      />
      <path
        d="m441.941406 401.066406-2.902344 43.265625c-.3125 4.476563-4.042968 7.945313-8.53125 7.933594h-349.015624c-4.488282.011719-8.21875-3.457031-8.53125-7.933594l-2.902344-43.265625zm0 0"
        fill="#795548"
      />
      <path
        d="m448.171875 307.796875-2.816406 42.070313h-151.296875c8.480468-9.34375 13.167968-21.515626 13.140625-34.132813v-17.066406h132.4375c2.371093-.007813 4.636719.972656 6.257812 2.707031 1.617188 1.730469 2.441407 4.058594 2.277344 6.421875zm0 0"
        fill="#795548"
      />
      <path
        d="m153.601562 8.535156c-35.757812 0-67.160156 11.433594-85.421874 34.132813-7.996094 9.859375-13.316407 21.617187-15.445313 34.132812-1.042969 5.628907-1.558594 11.339844-1.535156 17.066407v17.066406h-42.664063v-8.535156c-.015625-24.898438 9.871094-48.78125 27.476563-66.386719s41.488281-27.492188 66.386719-27.476563zm0 0"
        fill="#fec108"
      />
      <path
        d="m503.464844 102.398438v8.535156h-42.664063v-17.066406c.023438-5.726563-.492187-11.4375-1.535156-17.066407-2.128906-12.515625-7.449219-24.273437-15.445313-34.132812-18.261718-22.699219-49.664062-34.132813-85.421874-34.132813h51.203124c24.898438-.015625 48.78125 9.871094 66.386719 27.476563s27.492188 41.488281 27.476563 66.386719zm0 0"
        fill="#ff9801"
      />
      <path
        d="m477.867188 102.398438v8.535156h-17.066407v-17.066406c.023438-5.726563-.492187-11.4375-1.535156-17.066407-2.128906-12.515625-7.449219-24.273437-15.445313-34.132812-18.261718-22.699219-49.664062-34.132813-85.421874-34.132813h25.601562c24.898438-.015625 48.78125 9.871094 66.390625 27.476563 17.605469 17.605469 27.488281 41.488281 27.476563 66.386719zm0 0"
        fill="#fec108"
      />
      <path
        d="m460.800781 93.867188v17.066406h-409.601562v-17.066406c-.023438-5.726563.492187-11.4375 1.535156-17.066407h406.53125c1.042969 5.628907 1.558594 11.339844 1.535156 17.066407zm0 0"
        fill="#795548"
      />
      <path
        d="m459.265625 76.800781h-406.53125c2.128906-12.515625 7.449219-24.273437 15.445313-34.132812h375.640624c7.996094 9.859375 13.316407 21.617187 15.445313 34.132812zm0 0"
        fill="#bfa885"
      />
      <path
        d="m422.484375 241.152344-39.253906-23.210938-10.664063-41.558594 6.570313 3.839844 32.597656 19.285156zm0 0"
        fill="#2296f3"
      />
      <path
        d="m359.425781 168.617188-41.472656 10.753906-39.253906-23.210938 41.558593-10.667968 32.679688 19.285156zm0 0"
        fill="#2296f3"
      />
      <path
        d="m383.230469 217.941406-64.167969-37.886718-.171875-.085938-.9375-.597656 41.472656-10.753906h.082031l13.058594 7.765624zm0 0"
        fill="#00bcd4"
      />
      <path
        d="m317.953125 179.371094-.085937.339844-13.054688 67.753906h-13.996094l-9.898437-74.75-2.21875-16.554688zm0 0"
        fill="#3f51b5"
      />
      <path
        d="m383.230469 217.941406-35.496094 29.523438h-42.921875l13.054688-67.753906 1.023437.257812.171875.085938zm0 0"
        fill="#2296f3"
      />
      <path
        d="m422.484375 241.152344-16.210937 6.3125h-58.539063l35.496094-29.523438zm0 0"
        fill="#3f51b5"
      />
      <path
        d="m226.476562 206.847656-7.511718 40.617188h-34.816406l5.121093-27.476563zm0 0"
        fill="#c52828"
      />
      <path
        d="m226.476562 206.847656-37.207031 13.140625h-.085937l-22.273438-19.113281 7.167969-38.742188 17.070313 14.59375zm0 0"
        fill="#f44335"
      />
      <path
        d="m174.078125 162.132812-7.167969 38.742188-27.816406 9.8125-29.953125-25.597656zm0 0"
        fill="#e91e63"
      />
      <path
        d="m139.09375 210.6875-5.375 28.929688-22.1875 7.847656h-13.910156l5.886718-31.914063 5.632813-30.460937zm0 0"
        fill="#e91e63"
      />
      <path d="m470.433594 512h-428.867188c-13.464844-.027344-24.617187-10.460938-25.539062-23.894531l-14.789063-221.867188c-.472656-7.066406 2.003907-14.015625 6.84375-19.191406 4.84375-5.175781 11.609375-8.113281 18.695313-8.113281h458.445312c7.085938 0 13.851563 2.9375 18.695313 8.113281 4.839843 5.175781 7.316406 12.125 6.84375 19.191406l-14.789063 221.867188c-.921875 13.433593-12.074218 23.867187-25.539062 23.894531zm-443.65625-256c-2.363282-.003906-4.625.972656-6.242188 2.695312-1.617187 1.722657-2.445312 4.042969-2.289062 6.402344l14.785156 221.867188c.296875 4.488281 4.035156 7.976562 8.535156 7.96875h428.867188c4.5.011718 8.234375-3.472656 8.535156-7.960938l14.785156-221.867187c.15625-2.359375-.671875-4.675781-2.289062-6.402344-1.617188-1.722656-3.878906-2.699219-6.242188-2.695313zm0 0" />
      <path d="m430.539062 460.800781h-349.078124c-8.992188-.007812-16.445313-6.964843-17.070313-15.933593l-9.101563-136.53125c-.316406-4.71875 1.34375-9.359376 4.578126-12.808594 3.234374-3.453125 7.757812-5.40625 12.488281-5.394532h132.445312c4.710938 0 8.53125 3.820313 8.53125 8.535157 0 4.710937-3.820312 8.53125-8.53125 8.53125h-132.445312l9.105469 136.535156h349.078124l9.105469-136.535156h-132.445312c-4.710938 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820312-8.535157 8.53125-8.535157h132.445312c4.734375-.011718 9.257813 1.945313 12.492188 5.398438 3.234375 3.449219 4.894531 8.089844 4.574219 12.8125l-9.101563 136.53125c-.628906 8.964844-8.082031 15.921875-17.070313 15.925781zm0 0" />
      <path d="m503.464844 119.464844h-494.929688c-4.714844 0-8.535156-3.820313-8.535156-8.53125v-8.535156c.0625-56.527344 45.871094-102.335938 102.398438-102.398438h307.203124c56.527344.0625 102.335938 45.871094 102.398438 102.398438v8.535156c0 4.710937-3.820312 8.53125-8.535156 8.53125zm-486.398438-17.066406h477.867188c-.050782-47.105469-38.226563-85.28125-85.332032-85.332032h-307.203124c-47.105469.050782-85.28125 38.226563-85.332032 85.332032zm0 0" />
      <path d="m460.800781 119.464844h-409.601562c-4.710938 0-8.53125-3.820313-8.53125-8.53125v-17.066406c0-57.019532 43.519531-93.867188 110.933593-93.867188h204.796876c67.414062 0 110.933593 36.847656 110.933593 93.867188v17.066406c0 4.710937-3.820312 8.53125-8.53125 8.53125zm-401.066406-17.066406h392.53125v-8.53125c0-56.628907-48.492187-76.800782-93.867187-76.800782h-204.796876c-45.375 0-93.867187 20.171875-93.867187 76.800782zm0 0" />
      <path d="m256 375.464844c-32.972656-.035156-59.695312-26.757813-59.734375-59.730469v-68.269531c0-4.710938 3.820313-8.53125 8.535156-8.53125h102.398438c4.714843 0 8.535156 3.820312 8.535156 8.53125v68.269531c-.039063 32.972656-26.761719 59.695313-59.734375 59.730469zm-42.667969-119.464844v59.734375c0 23.5625 19.105469 42.664063 42.667969 42.664063s42.667969-19.101563 42.667969-42.664063v-59.734375zm0 0" />
      <path d="m256 324.265625c-14.136719 0-25.601562-11.460937-25.601562-25.597656 0-14.140625 11.464843-25.601563 25.601562-25.601563s25.601562 11.460938 25.601562 25.601563c0 14.136719-11.464843 25.597656-25.601562 25.597656zm0-34.132813c-4.710938 0-8.535156 3.820313-8.535156 8.535157 0 4.710937 3.824218 8.53125 8.535156 8.53125s8.535156-3.820313 8.535156-8.53125c0-4.714844-3.824218-8.535157-8.535156-8.535157zm0 0" />
      <path d="m454.910156 85.332031h-397.820312c-4.714844 0-8.535156-3.820312-8.535156-8.53125 0-4.714843 3.820312-8.535156 8.535156-8.535156h397.820312c4.714844 0 8.535156 3.820313 8.535156 8.535156 0 4.710938-3.820312 8.53125-8.535156 8.53125zm0 0" />
      <path d="m434.773438 51.199219h-357.546876c-4.710937 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.824219-8.535157 8.535156-8.535157h357.546876c4.710937 0 8.535156 3.820313 8.535156 8.535157 0 4.710937-3.824219 8.53125-8.535156 8.53125zm0 0" />
      <path d="m256 341.332031c-4.710938 0-8.535156-3.820312-8.535156-8.53125v-17.066406c0-4.714844 3.824218-8.535156 8.535156-8.535156s8.535156 3.820312 8.535156 8.535156v17.066406c0 4.710938-3.824218 8.53125-8.535156 8.53125zm0 0" />
      <path d="m445.355469 358.398438h-151.296875c-4.710938 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.824218-8.535157 8.535156-8.535157h151.296875c4.710937 0 8.53125 3.820313 8.53125 8.535157 0 4.710937-3.820313 8.53125-8.53125 8.53125zm0 0" />
      <path d="m217.941406 358.398438h-151.296875c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535157 8.53125-8.535157h151.296875c4.710938 0 8.535156 3.820313 8.535156 8.535157 0 4.710937-3.824218 8.53125-8.535156 8.53125zm0 0" />
      <path d="m441.941406 409.601562h-371.882812c-4.710938 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.824218-8.53125 8.535156-8.53125h371.882812c4.710938 0 8.535156 3.820313 8.535156 8.53125 0 4.714844-3.824218 8.535156-8.535156 8.535156zm0 0" />
      <path d="m319 188.585938c-.96875 0-1.929688-.167969-2.839844-.496094l-.316406-.074219c-2.960938-.722656-5.316406-2.972656-6.171875-5.898437-.855469-2.929688-.085937-6.089844 2.023437-8.296876 2.105469-2.203124 5.230469-3.117187 8.195313-2.394531l.5625.144531c.828125.203126 1.632813.488282 2.40625.855469 4.191406 2.191407 5.832031 7.351563 3.675781 11.5625-1.460937 2.8125-4.363281 4.585938-7.535156 4.597657zm0 0" />
      <path d="m443.734375 256c-1.175781.003906-2.339844-.242188-3.414063-.71875-2.074218-.902344-3.703124-2.597656-4.53125-4.703125-.824218-2.109375-.777343-4.460937.128907-6.535156l59.730469-136.53125c1.976562-4.167969 6.902343-6.011719 11.128906-4.167969 4.226562 1.847656 6.21875 6.714844 4.503906 10.996094l-59.730469 136.53125c-1.355469 3.113281-4.425781 5.125-7.816406 5.128906zm0 0" />
      <path d="m191.148438 185.257812c-4.363282.003907-8.023438-3.28125-8.492188-7.613281-.46875-4.335937 2.40625-8.328125 6.664062-9.257812 30.441407-6.574219 61.773438-7.972657 92.679688-4.136719 4.644531.625 7.917969 4.882812 7.324219 9.53125-.589844 4.648438-4.820313 7.953125-9.472657 7.398438-28.964843-3.578126-58.324218-2.265626-86.851562 3.882812-.609375.132812-1.230469.199219-1.851562.195312zm0 0" />
      <path d="m68.265625 256c-3.394531 0-6.464844-2.007812-7.824219-5.121094l-59.734375-136.53125c-1.714843-4.28125.28125-9.148437 4.507813-10.996094 4.226562-1.84375 9.152344 0 11.125 4.167969l59.734375 136.535157c1.882812 4.3125-.082031 9.339843-4.394531 11.226562-1.074219.476562-2.238282.722656-3.414063.71875zm0 0" />
      <path d="m422.46875 249.652344c-1.527344-.003906-3.023438-.410156-4.335938-1.1875l-143.761718-84.921875c-2.972656-1.757813-4.597656-5.132813-4.109375-8.550781.484375-3.421876 2.984375-6.210938 6.328125-7.066407l41.574218-10.691406c2.191407-.574219 4.523438-.246094 6.46875.910156l32.554688 19.234375.121094.078125 58.8125 34.738281c1.949218 1.152344 3.359375 3.03125 3.921875 5.222657l10.695312 41.574219c.65625 2.554687.09375 5.273437-1.523437 7.355468-1.617188 2.085938-4.109375 3.304688-6.746094 3.304688zm-121.675781-90.328125 108.277343 64-4.675781-18.199219-29.449219-17.390625-.1875-.113281-55.765624-32.945313zm0 0" />
      <path d="m347.742188 256c-3.597657.007812-6.8125-2.242188-8.039063-5.625s-.199219-7.171875 2.570313-9.46875l31.351562-26.070312-8.449219-32.921876-9.386719-5.582031c-.238281-.109375-.476562-.238281-.71875-.375l-39.148437-23.117187c-4.0625-2.394532-5.410156-7.625-3.015625-11.6875 2.390625-4.058594 7.625-5.410156 11.683594-3.015625l38.539062 22.75c.253906.121093.503906.257812.742188.394531l13.054687 7.773438c1.9375 1.148437 3.34375 3.019531 3.910157 5.203124l10.664062 41.566407c.820312 3.191406-.273438 6.570312-2.808594 8.679687l-35.496094 29.523438c-1.53125 1.273437-3.460937 1.972656-5.453124 1.972656zm0 0" />
      <path d="m304.820312 256c-.546874 0-1.09375-.050781-1.632812-.152344-4.621094-.894531-7.648438-5.367187-6.757812-9.992187l13.144531-68.097657c.621093-3.234374 3.046875-5.820312 6.234375-6.648437l41.472656-10.75c2.234375-.5625 4.597656-.234375 6.589844.921875l52.207031 30.882812c4.054687 2.398438 5.402344 7.632813 3.003906 11.6875-2.398437 4.058594-7.632812 5.402344-11.691406 3.003907l-49.203125-29.097657-32.878906 8.535157-12.136719 62.785156c-.769531 4.007813-4.269531 6.910156-8.351563 6.921875zm0 0" />
      <path d="m290.808594 256c-4.277344-.007812-7.890625-3.175781-8.449219-7.414062l-12.117187-91.308594c-.492188-4.601563 2.773437-8.761719 7.363281-9.367188 4.589843-.609375 8.824219 2.550782 9.550781 7.125l12.117188 91.304688c.324218 2.441406-.421876 4.898437-2.042969 6.75-1.621094 1.851562-3.960938 2.910156-6.421875 2.910156zm0 0" />
      <path d="m406.273438 256c-4.109376.003906-7.632813-2.921875-8.394532-6.957031-.757812-4.035157 1.46875-8.039063 5.296875-9.53125l16.210938-6.3125c4.371093-1.640625 9.25.539062 10.945312 4.886719 1.699219 4.351562-.414062 9.257812-4.742187 11.011718l-16.210938 6.320313c-.992187.382812-2.042968.582031-3.105468.582031zm0 0" />
      <path d="m218.972656 256c-.519531.003906-1.039062-.046875-1.550781-.144531-4.628906-.863281-7.683594-5.3125-6.828125-9.941407l6.613281-35.773437-44.910156-38.320313-55.644531 19.625-10.617188 57.523438c-.550781 3-2.660156 5.472656-5.535156 6.496094-2.871094 1.019531-6.070312.433594-8.390625-1.546875-2.320313-1.976563-3.410156-5.039063-2.859375-8.039063l11.519531-62.386718c.554688-3 2.671875-5.476563 5.546875-6.492188l64.9375-22.957031c2.875-1.015625 6.074219-.417969 8.390625 1.5625l52.375 44.707031c2.324219 1.976562 3.414063 5.046875 2.859375 8.046875l-7.5 40.617187c-.730468 4.066407-4.273437 7.027344-8.40625 7.023438zm0 0" />
      <path d="m184.15625 256c-.523438 0-1.050781-.046875-1.570312-.144531-4.628907-.863281-7.6875-5.316407-6.824219-9.949219l4.207031-22.589844-14.875-12.800781-18.507812 6.539063-3.59375 19.320312 5.386718 4.570312c3.589844 3.066407 4.019532 8.460938.953125 12.050782-3.0625 3.589844-8.457031 4.019531-12.046875.957031l-9.140625-7.765625c-1.90625-1.621094-3.003906-4-3.003906-6.503906.003906-.550782.050781-1.101563.144531-1.644532l5.367188-28.929687c.5625-3 2.679687-5.472656 5.558594-6.484375l27.816406-9.8125c2.878906-1.019531 6.082031-.417969 8.398437 1.566406l21.828125 18.773438c.554688.40625 1.058594.878906 1.492188 1.410156 1.636718 1.941406 2.320312 4.515625 1.867187 7.015625l-5.117187 27.476563c-.761719 4.011718-4.253906 6.921874-8.339844 6.945312zm0 0" />
      <path d="m137.277344 219.734375c-2.152344 0-4.226563-.808594-5.804688-2.269531l-28.160156-26.085938c-3.457031-3.207031-3.660156-8.605468-.457031-12.0625 3.207031-3.457031 8.605469-3.664062 12.0625-.457031l28.160156 26.085937c2.574219 2.386719 3.421875 6.105469 2.140625 9.371094s-4.433594 5.417969-7.941406 5.417969zm0 0" />
      <path d="m111.53125 256c-4.160156.003906-7.714844-2.988281-8.421875-7.085938-.703125-4.101562 1.648437-8.109374 5.570313-9.496093l22.1875-7.847657c4.40625-1.441406 9.15625.902344 10.699218 5.277344 1.539063 4.371094-.695312 9.175782-5.03125 10.816406l-22.1875 7.847657c-.90625.320312-1.859375.484375-2.816406.488281zm0 0" />
      <path d="m189.175781 228.53125c-4.164062.007812-7.726562-2.992188-8.429687-7.097656-.699219-4.105469 1.664062-8.117188 5.597656-9.492188l37.273438-13.113281c4.40625-1.441406 9.15625.902344 10.695312 5.277344 1.539062 4.371093-.695312 9.175781-5.03125 10.816406l-37.28125 13.121094c-.90625.320312-1.863281.484375-2.824219.488281zm0 0" />
      <path d="m169.480469 208.382812c-.351563 0-.699219 0-1.058594-.058593-2.246094-.277344-4.289063-1.4375-5.679687-3.226563-1.390626-1.785156-2.015626-4.050781-1.734376-6.296875l4.597657-37.691406c.566406-4.683594 4.820312-8.019531 9.503906-7.453125 4.679687.566406 8.019531 4.820312 7.453125 9.5l-4.617188 37.734375c-.523437 4.277344-4.15625 7.492187-8.464843 7.492187zm0 0" />
    </svg>
  );
}

export function TreasureMobile(): ReactElement {
  return (
    <svg height="3rem" viewBox="0 -40 512 512" width="3rem" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m503.464844 102.398438v8.535156h-494.929688v-8.535156c-.015625-24.898438 9.871094-48.78125 27.476563-66.386719s41.488281-27.492188 66.386719-27.476563h307.203124c24.898438-.015625 48.78125 9.871094 66.386719 27.476563s27.492188 41.488281 27.476563 66.386719zm0 0"
        fill="#795548"
      />
      <path
        d="m503.464844 110.933594-59.730469 136.53125h-375.46875l-59.730469-136.53125 234.410156-12.117188 12.796876-.683594zm0 0"
        fill="#4e342e"
      />
      <path
        d="m477.867188 110.933594-59.734376 136.53125h-349.867187l-59.730469-136.53125 234.410156-12.117188zm0 0"
        fill="#795548"
      />
      <path
        d="m104.269531 249.070312 27.734375-21.914062 16.792969 12.460938 6.9375 24.113281zm0 0"
        fill="#802f34"
      />
      <path
        d="m502.273438 265.644531-14.847657 221.863281c-.585937 8.957032-8.007812 15.933594-16.984375 15.957032h-428.882812c-8.976563-.023438-16.398438-7-16.984375-15.957032l-14.847657-221.863281c-.289062-4.714843 1.375-9.335937 4.609376-12.777343 3.230468-3.441407 7.738281-5.394532 12.457031-5.402344h458.414062c4.71875.007812 9.226563 1.960937 12.457031 5.402344 3.234376 3.441406 4.898438 8.0625 4.609376 12.777343zm0 0"
        fill="#f57c00"
      />
      <path
        d="m477.867188 265.644531-14.847657 221.863281c-.585937 8.957032-8.007812 15.933594-16.980469 15.957032h-404.480468c-8.976563-.023438-16.398438-7-16.984375-15.957032l-14.847657-221.863281c-.289062-4.714843 1.375-9.335937 4.609376-12.777343 3.230468-3.441407 7.738281-5.394532 12.457031-5.402344h434.007812c4.71875.007812 9.230469 1.960937 12.460938 5.402344 3.230469 3.441406 4.898437 8.0625 4.605469 12.777343zm0 0"
        fill="#ff9801"
      />
      <path
        d="m213.691406 258.492188-8.378906-47.789063-14.164062-33.976563c18.496093-4.066406 37.378906-6.097656 56.316406-6.058593 11.183594-.027344 22.355468.660156 33.453125 2.046875l23.894531 85.777344zm0 0"
        fill="#fec108"
      />
      <path
        d="m189.269531 219.988281-5.121093 27.476563h-41.300782l-9.128906-7.761719v-.085937l5.375-28.929688 27.816406-9.8125 22.273438 19.113281zm0 0"
        fill="#f44335"
      />
      <path d="m317.867188 179.710938.085937-.339844.9375.597656zm0 0" fill="#804537" />
      <path
        d="m273.066406 298.667969c0 9.425781-7.640625 17.066406-17.066406 17.066406s-17.066406-7.640625-17.066406-17.066406 7.640625-17.066407 17.066406-17.066407 17.066406 7.640626 17.066406 17.066407zm0 0"
        fill="#607d8b"
      />
      <path
        d="m204.800781 247.464844v68.269531c0 28.277344 22.921875 51.199219 51.199219 51.199219s51.199219-22.921875 51.199219-51.199219v-68.269531zm51.199219 68.269531c-9.425781 0-17.066406-7.640625-17.066406-17.066406s7.640625-17.066407 17.066406-17.066407 17.066406 7.640626 17.066406 17.066407c-.027344 9.414062-7.652344 17.039062-17.066406 17.066406zm0 0"
        fill="#fec108"
      />
      <path
        d="m217.941406 349.867188h-151.296875l-2.816406-42.070313c-.164063-2.363281.660156-4.691406 2.277344-6.421875 1.621093-1.734375 3.886719-2.714844 6.257812-2.707031h132.4375v17.066406c-.027343 12.617187 4.660157 24.789063 13.140625 34.132813zm0 0"
        fill="#795548"
      />
      <path
        d="m445.355469 349.867188-3.414063 51.199218h-371.882812l-3.414063-51.199218h151.296875c9.671875 10.855468 23.519532 17.066406 38.058594 17.066406s28.386719-6.210938 38.058594-17.066406zm0 0"
        fill="#bfa885"
      />
      <path
        d="m441.941406 401.066406-2.902344 43.265625c-.3125 4.476563-4.042968 7.945313-8.53125 7.933594h-349.015624c-4.488282.011719-8.21875-3.457031-8.53125-7.933594l-2.902344-43.265625zm0 0"
        fill="#795548"
      />
      <path
        d="m448.171875 307.796875-2.816406 42.070313h-151.296875c8.480468-9.34375 13.167968-21.515626 13.140625-34.132813v-17.066406h132.4375c2.371093-.007813 4.636719.972656 6.257812 2.707031 1.617188 1.730469 2.441407 4.058594 2.277344 6.421875zm0 0"
        fill="#795548"
      />
      <path
        d="m153.601562 8.535156c-35.757812 0-67.160156 11.433594-85.421874 34.132813-7.996094 9.859375-13.316407 21.617187-15.445313 34.132812-1.042969 5.628907-1.558594 11.339844-1.535156 17.066407v17.066406h-42.664063v-8.535156c-.015625-24.898438 9.871094-48.78125 27.476563-66.386719s41.488281-27.492188 66.386719-27.476563zm0 0"
        fill="#fec108"
      />
      <path
        d="m503.464844 102.398438v8.535156h-42.664063v-17.066406c.023438-5.726563-.492187-11.4375-1.535156-17.066407-2.128906-12.515625-7.449219-24.273437-15.445313-34.132812-18.261718-22.699219-49.664062-34.132813-85.421874-34.132813h51.203124c24.898438-.015625 48.78125 9.871094 66.386719 27.476563s27.492188 41.488281 27.476563 66.386719zm0 0"
        fill="#ff9801"
      />
      <path
        d="m477.867188 102.398438v8.535156h-17.066407v-17.066406c.023438-5.726563-.492187-11.4375-1.535156-17.066407-2.128906-12.515625-7.449219-24.273437-15.445313-34.132812-18.261718-22.699219-49.664062-34.132813-85.421874-34.132813h25.601562c24.898438-.015625 48.78125 9.871094 66.390625 27.476563 17.605469 17.605469 27.488281 41.488281 27.476563 66.386719zm0 0"
        fill="#fec108"
      />
      <path
        d="m460.800781 93.867188v17.066406h-409.601562v-17.066406c-.023438-5.726563.492187-11.4375 1.535156-17.066407h406.53125c1.042969 5.628907 1.558594 11.339844 1.535156 17.066407zm0 0"
        fill="#795548"
      />
      <path
        d="m459.265625 76.800781h-406.53125c2.128906-12.515625 7.449219-24.273437 15.445313-34.132812h375.640624c7.996094 9.859375 13.316407 21.617187 15.445313 34.132812zm0 0"
        fill="#bfa885"
      />
      <path
        d="m422.484375 241.152344-39.253906-23.210938-10.664063-41.558594 6.570313 3.839844 32.597656 19.285156zm0 0"
        fill="#2296f3"
      />
      <path
        d="m359.425781 168.617188-41.472656 10.753906-39.253906-23.210938 41.558593-10.667968 32.679688 19.285156zm0 0"
        fill="#2296f3"
      />
      <path
        d="m383.230469 217.941406-64.167969-37.886718-.171875-.085938-.9375-.597656 41.472656-10.753906h.082031l13.058594 7.765624zm0 0"
        fill="#00bcd4"
      />
      <path
        d="m317.953125 179.371094-.085937.339844-13.054688 67.753906h-13.996094l-9.898437-74.75-2.21875-16.554688zm0 0"
        fill="#3f51b5"
      />
      <path
        d="m383.230469 217.941406-35.496094 29.523438h-42.921875l13.054688-67.753906 1.023437.257812.171875.085938zm0 0"
        fill="#2296f3"
      />
      <path
        d="m422.484375 241.152344-16.210937 6.3125h-58.539063l35.496094-29.523438zm0 0"
        fill="#3f51b5"
      />
      <path
        d="m226.476562 206.847656-7.511718 40.617188h-34.816406l5.121093-27.476563zm0 0"
        fill="#c52828"
      />
      <path
        d="m226.476562 206.847656-37.207031 13.140625h-.085937l-22.273438-19.113281 7.167969-38.742188 17.070313 14.59375zm0 0"
        fill="#f44335"
      />
      <path
        d="m174.078125 162.132812-7.167969 38.742188-27.816406 9.8125-29.953125-25.597656zm0 0"
        fill="#e91e63"
      />
      <path
        d="m139.09375 210.6875-5.375 28.929688-22.1875 7.847656h-13.910156l5.886718-31.914063 5.632813-30.460937zm0 0"
        fill="#e91e63"
      />
      <path d="m470.433594 512h-428.867188c-13.464844-.027344-24.617187-10.460938-25.539062-23.894531l-14.789063-221.867188c-.472656-7.066406 2.003907-14.015625 6.84375-19.191406 4.84375-5.175781 11.609375-8.113281 18.695313-8.113281h458.445312c7.085938 0 13.851563 2.9375 18.695313 8.113281 4.839843 5.175781 7.316406 12.125 6.84375 19.191406l-14.789063 221.867188c-.921875 13.433593-12.074218 23.867187-25.539062 23.894531zm-443.65625-256c-2.363282-.003906-4.625.972656-6.242188 2.695312-1.617187 1.722657-2.445312 4.042969-2.289062 6.402344l14.785156 221.867188c.296875 4.488281 4.035156 7.976562 8.535156 7.96875h428.867188c4.5.011718 8.234375-3.472656 8.535156-7.960938l14.785156-221.867187c.15625-2.359375-.671875-4.675781-2.289062-6.402344-1.617188-1.722656-3.878906-2.699219-6.242188-2.695313zm0 0" />
      <path d="m430.539062 460.800781h-349.078124c-8.992188-.007812-16.445313-6.964843-17.070313-15.933593l-9.101563-136.53125c-.316406-4.71875 1.34375-9.359376 4.578126-12.808594 3.234374-3.453125 7.757812-5.40625 12.488281-5.394532h132.445312c4.710938 0 8.53125 3.820313 8.53125 8.535157 0 4.710937-3.820312 8.53125-8.53125 8.53125h-132.445312l9.105469 136.535156h349.078124l9.105469-136.535156h-132.445312c-4.710938 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820312-8.535157 8.53125-8.535157h132.445312c4.734375-.011718 9.257813 1.945313 12.492188 5.398438 3.234375 3.449219 4.894531 8.089844 4.574219 12.8125l-9.101563 136.53125c-.628906 8.964844-8.082031 15.921875-17.070313 15.925781zm0 0" />
      <path d="m503.464844 119.464844h-494.929688c-4.714844 0-8.535156-3.820313-8.535156-8.53125v-8.535156c.0625-56.527344 45.871094-102.335938 102.398438-102.398438h307.203124c56.527344.0625 102.335938 45.871094 102.398438 102.398438v8.535156c0 4.710937-3.820312 8.53125-8.535156 8.53125zm-486.398438-17.066406h477.867188c-.050782-47.105469-38.226563-85.28125-85.332032-85.332032h-307.203124c-47.105469.050782-85.28125 38.226563-85.332032 85.332032zm0 0" />
      <path d="m460.800781 119.464844h-409.601562c-4.710938 0-8.53125-3.820313-8.53125-8.53125v-17.066406c0-57.019532 43.519531-93.867188 110.933593-93.867188h204.796876c67.414062 0 110.933593 36.847656 110.933593 93.867188v17.066406c0 4.710937-3.820312 8.53125-8.53125 8.53125zm-401.066406-17.066406h392.53125v-8.53125c0-56.628907-48.492187-76.800782-93.867187-76.800782h-204.796876c-45.375 0-93.867187 20.171875-93.867187 76.800782zm0 0" />
      <path d="m256 375.464844c-32.972656-.035156-59.695312-26.757813-59.734375-59.730469v-68.269531c0-4.710938 3.820313-8.53125 8.535156-8.53125h102.398438c4.714843 0 8.535156 3.820312 8.535156 8.53125v68.269531c-.039063 32.972656-26.761719 59.695313-59.734375 59.730469zm-42.667969-119.464844v59.734375c0 23.5625 19.105469 42.664063 42.667969 42.664063s42.667969-19.101563 42.667969-42.664063v-59.734375zm0 0" />
      <path d="m256 324.265625c-14.136719 0-25.601562-11.460937-25.601562-25.597656 0-14.140625 11.464843-25.601563 25.601562-25.601563s25.601562 11.460938 25.601562 25.601563c0 14.136719-11.464843 25.597656-25.601562 25.597656zm0-34.132813c-4.710938 0-8.535156 3.820313-8.535156 8.535157 0 4.710937 3.824218 8.53125 8.535156 8.53125s8.535156-3.820313 8.535156-8.53125c0-4.714844-3.824218-8.535157-8.535156-8.535157zm0 0" />
      <path d="m454.910156 85.332031h-397.820312c-4.714844 0-8.535156-3.820312-8.535156-8.53125 0-4.714843 3.820312-8.535156 8.535156-8.535156h397.820312c4.714844 0 8.535156 3.820313 8.535156 8.535156 0 4.710938-3.820312 8.53125-8.535156 8.53125zm0 0" />
      <path d="m434.773438 51.199219h-357.546876c-4.710937 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.824219-8.535157 8.535156-8.535157h357.546876c4.710937 0 8.535156 3.820313 8.535156 8.535157 0 4.710937-3.824219 8.53125-8.535156 8.53125zm0 0" />
      <path d="m256 341.332031c-4.710938 0-8.535156-3.820312-8.535156-8.53125v-17.066406c0-4.714844 3.824218-8.535156 8.535156-8.535156s8.535156 3.820312 8.535156 8.535156v17.066406c0 4.710938-3.824218 8.53125-8.535156 8.53125zm0 0" />
      <path d="m445.355469 358.398438h-151.296875c-4.710938 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.824218-8.535157 8.535156-8.535157h151.296875c4.710937 0 8.53125 3.820313 8.53125 8.535157 0 4.710937-3.820313 8.53125-8.53125 8.53125zm0 0" />
      <path d="m217.941406 358.398438h-151.296875c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535157 8.53125-8.535157h151.296875c4.710938 0 8.535156 3.820313 8.535156 8.535157 0 4.710937-3.824218 8.53125-8.535156 8.53125zm0 0" />
      <path d="m441.941406 409.601562h-371.882812c-4.710938 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.824218-8.53125 8.535156-8.53125h371.882812c4.710938 0 8.535156 3.820313 8.535156 8.53125 0 4.714844-3.824218 8.535156-8.535156 8.535156zm0 0" />
      <path d="m319 188.585938c-.96875 0-1.929688-.167969-2.839844-.496094l-.316406-.074219c-2.960938-.722656-5.316406-2.972656-6.171875-5.898437-.855469-2.929688-.085937-6.089844 2.023437-8.296876 2.105469-2.203124 5.230469-3.117187 8.195313-2.394531l.5625.144531c.828125.203126 1.632813.488282 2.40625.855469 4.191406 2.191407 5.832031 7.351563 3.675781 11.5625-1.460937 2.8125-4.363281 4.585938-7.535156 4.597657zm0 0" />
      <path d="m443.734375 256c-1.175781.003906-2.339844-.242188-3.414063-.71875-2.074218-.902344-3.703124-2.597656-4.53125-4.703125-.824218-2.109375-.777343-4.460937.128907-6.535156l59.730469-136.53125c1.976562-4.167969 6.902343-6.011719 11.128906-4.167969 4.226562 1.847656 6.21875 6.714844 4.503906 10.996094l-59.730469 136.53125c-1.355469 3.113281-4.425781 5.125-7.816406 5.128906zm0 0" />
      <path d="m191.148438 185.257812c-4.363282.003907-8.023438-3.28125-8.492188-7.613281-.46875-4.335937 2.40625-8.328125 6.664062-9.257812 30.441407-6.574219 61.773438-7.972657 92.679688-4.136719 4.644531.625 7.917969 4.882812 7.324219 9.53125-.589844 4.648438-4.820313 7.953125-9.472657 7.398438-28.964843-3.578126-58.324218-2.265626-86.851562 3.882812-.609375.132812-1.230469.199219-1.851562.195312zm0 0" />
      <path d="m68.265625 256c-3.394531 0-6.464844-2.007812-7.824219-5.121094l-59.734375-136.53125c-1.714843-4.28125.28125-9.148437 4.507813-10.996094 4.226562-1.84375 9.152344 0 11.125 4.167969l59.734375 136.535157c1.882812 4.3125-.082031 9.339843-4.394531 11.226562-1.074219.476562-2.238282.722656-3.414063.71875zm0 0" />
      <path d="m422.46875 249.652344c-1.527344-.003906-3.023438-.410156-4.335938-1.1875l-143.761718-84.921875c-2.972656-1.757813-4.597656-5.132813-4.109375-8.550781.484375-3.421876 2.984375-6.210938 6.328125-7.066407l41.574218-10.691406c2.191407-.574219 4.523438-.246094 6.46875.910156l32.554688 19.234375.121094.078125 58.8125 34.738281c1.949218 1.152344 3.359375 3.03125 3.921875 5.222657l10.695312 41.574219c.65625 2.554687.09375 5.273437-1.523437 7.355468-1.617188 2.085938-4.109375 3.304688-6.746094 3.304688zm-121.675781-90.328125 108.277343 64-4.675781-18.199219-29.449219-17.390625-.1875-.113281-55.765624-32.945313zm0 0" />
      <path d="m347.742188 256c-3.597657.007812-6.8125-2.242188-8.039063-5.625s-.199219-7.171875 2.570313-9.46875l31.351562-26.070312-8.449219-32.921876-9.386719-5.582031c-.238281-.109375-.476562-.238281-.71875-.375l-39.148437-23.117187c-4.0625-2.394532-5.410156-7.625-3.015625-11.6875 2.390625-4.058594 7.625-5.410156 11.683594-3.015625l38.539062 22.75c.253906.121093.503906.257812.742188.394531l13.054687 7.773438c1.9375 1.148437 3.34375 3.019531 3.910157 5.203124l10.664062 41.566407c.820312 3.191406-.273438 6.570312-2.808594 8.679687l-35.496094 29.523438c-1.53125 1.273437-3.460937 1.972656-5.453124 1.972656zm0 0" />
      <path d="m304.820312 256c-.546874 0-1.09375-.050781-1.632812-.152344-4.621094-.894531-7.648438-5.367187-6.757812-9.992187l13.144531-68.097657c.621093-3.234374 3.046875-5.820312 6.234375-6.648437l41.472656-10.75c2.234375-.5625 4.597656-.234375 6.589844.921875l52.207031 30.882812c4.054687 2.398438 5.402344 7.632813 3.003906 11.6875-2.398437 4.058594-7.632812 5.402344-11.691406 3.003907l-49.203125-29.097657-32.878906 8.535157-12.136719 62.785156c-.769531 4.007813-4.269531 6.910156-8.351563 6.921875zm0 0" />
      <path d="m290.808594 256c-4.277344-.007812-7.890625-3.175781-8.449219-7.414062l-12.117187-91.308594c-.492188-4.601563 2.773437-8.761719 7.363281-9.367188 4.589843-.609375 8.824219 2.550782 9.550781 7.125l12.117188 91.304688c.324218 2.441406-.421876 4.898437-2.042969 6.75-1.621094 1.851562-3.960938 2.910156-6.421875 2.910156zm0 0" />
      <path d="m406.273438 256c-4.109376.003906-7.632813-2.921875-8.394532-6.957031-.757812-4.035157 1.46875-8.039063 5.296875-9.53125l16.210938-6.3125c4.371093-1.640625 9.25.539062 10.945312 4.886719 1.699219 4.351562-.414062 9.257812-4.742187 11.011718l-16.210938 6.320313c-.992187.382812-2.042968.582031-3.105468.582031zm0 0" />
      <path d="m218.972656 256c-.519531.003906-1.039062-.046875-1.550781-.144531-4.628906-.863281-7.683594-5.3125-6.828125-9.941407l6.613281-35.773437-44.910156-38.320313-55.644531 19.625-10.617188 57.523438c-.550781 3-2.660156 5.472656-5.535156 6.496094-2.871094 1.019531-6.070312.433594-8.390625-1.546875-2.320313-1.976563-3.410156-5.039063-2.859375-8.039063l11.519531-62.386718c.554688-3 2.671875-5.476563 5.546875-6.492188l64.9375-22.957031c2.875-1.015625 6.074219-.417969 8.390625 1.5625l52.375 44.707031c2.324219 1.976562 3.414063 5.046875 2.859375 8.046875l-7.5 40.617187c-.730468 4.066407-4.273437 7.027344-8.40625 7.023438zm0 0" />
      <path d="m184.15625 256c-.523438 0-1.050781-.046875-1.570312-.144531-4.628907-.863281-7.6875-5.316407-6.824219-9.949219l4.207031-22.589844-14.875-12.800781-18.507812 6.539063-3.59375 19.320312 5.386718 4.570312c3.589844 3.066407 4.019532 8.460938.953125 12.050782-3.0625 3.589844-8.457031 4.019531-12.046875.957031l-9.140625-7.765625c-1.90625-1.621094-3.003906-4-3.003906-6.503906.003906-.550782.050781-1.101563.144531-1.644532l5.367188-28.929687c.5625-3 2.679687-5.472656 5.558594-6.484375l27.816406-9.8125c2.878906-1.019531 6.082031-.417969 8.398437 1.566406l21.828125 18.773438c.554688.40625 1.058594.878906 1.492188 1.410156 1.636718 1.941406 2.320312 4.515625 1.867187 7.015625l-5.117187 27.476563c-.761719 4.011718-4.253906 6.921874-8.339844 6.945312zm0 0" />
      <path d="m137.277344 219.734375c-2.152344 0-4.226563-.808594-5.804688-2.269531l-28.160156-26.085938c-3.457031-3.207031-3.660156-8.605468-.457031-12.0625 3.207031-3.457031 8.605469-3.664062 12.0625-.457031l28.160156 26.085937c2.574219 2.386719 3.421875 6.105469 2.140625 9.371094s-4.433594 5.417969-7.941406 5.417969zm0 0" />
      <path d="m111.53125 256c-4.160156.003906-7.714844-2.988281-8.421875-7.085938-.703125-4.101562 1.648437-8.109374 5.570313-9.496093l22.1875-7.847657c4.40625-1.441406 9.15625.902344 10.699218 5.277344 1.539063 4.371094-.695312 9.175782-5.03125 10.816406l-22.1875 7.847657c-.90625.320312-1.859375.484375-2.816406.488281zm0 0" />
      <path d="m189.175781 228.53125c-4.164062.007812-7.726562-2.992188-8.429687-7.097656-.699219-4.105469 1.664062-8.117188 5.597656-9.492188l37.273438-13.113281c4.40625-1.441406 9.15625.902344 10.695312 5.277344 1.539062 4.371093-.695312 9.175781-5.03125 10.816406l-37.28125 13.121094c-.90625.320312-1.863281.484375-2.824219.488281zm0 0" />
      <path d="m169.480469 208.382812c-.351563 0-.699219 0-1.058594-.058593-2.246094-.277344-4.289063-1.4375-5.679687-3.226563-1.390626-1.785156-2.015626-4.050781-1.734376-6.296875l4.597657-37.691406c.566406-4.683594 4.820312-8.019531 9.503906-7.453125 4.679687.566406 8.019531 4.820312 7.453125 9.5l-4.617188 37.734375c-.523437 4.277344-4.15625 7.492187-8.464843 7.492187zm0 0" />
    </svg>
  );
}
