import {
  Box,
  Button,
  Flex,
  FlexProps,
  HStack,
  useDisclosure,
  useColorModeValue as mode,
  Text,
  useBoolean,
  Image,
  Center,
  Grid,
  SimpleGrid,
  GridItem,
} from "@chakra-ui/react";
import * as React from "react";
import { Dragon } from "../../../icons/Dragon";
import Logo from "../../../images/Logo.png";
import { NavLink } from "./NavLink";
import { NavMenu } from "./NavMenu";
import { Submenu } from "./Submenu";
import { ToggleButton } from "./ToggleButton";
import { Link, navigate } from "gatsby";
import { links } from "./_data";
import { useAuthState } from "../../../context/AuthState";
import { AuthModal } from "../../auth/AuthModal";
import { Label, MinorTitle, SubtitleBold } from "../../styled/Text";
import { Community } from "../../../icons/Community";
import { Break } from "../../styled/Break";

const MobileNavContext = (props: FlexProps) => {
  const { isOpen, onToggle } = useDisclosure();
  const authState = useAuthState();

  const [showSignUpForm, setShowSignUpForm] = useBoolean();
  const [showLogInForm, setShowLogInForm] = useBoolean();

  return (
    <>
      {/* <Flex align="center" justify="space-between" className="nav-content__mobile" {...props}> */}
      <Grid templateColumns="repeat(10, 1fr)" gap={6}>
        <GridItem colSpan={3} mt="1vh">
          <HStack>
            <Image src={Logo} height="5vh" />
            <Link to="/">
              <Text fontSize="1.6rem" fontFamily="linotte" fontWeight={500}>
                EazyRPG
              </Text>
            </Link>
          </HStack>
        </GridItem>
        <GridItem colSpan={6} />
        <GridItem colSpan={1}>
          <ToggleButton isOpen={isOpen} onClick={onToggle} />
        </GridItem>
      </Grid>
      {/* </Flex> */}
      <NavMenu animate={isOpen ? "open" : "closed"}>
        {links.map((link, idx) => {
          if (link.loggedInRequired && !authState.loggedIn) {
            return;
          }
          return link.children ? (
            <Submenu.Mobile key={idx} link={link} />
          ) : (
            <NavLink.Mobile key={idx} onClick={() => navigate(link.href)}>
              {link.label}
            </NavLink.Mobile>
          );
        })}
        {authState.loggedIn ? (
          <Box>
            <Label text={`Welcome ${authState.user}!`} />
            <Break height="2vh" />
            <Box
              as="button"
              onClick={() => authState.logout().then(() => navigate("/"))}
              color="black"
              fontFamily="linotte"
              mr="1rem"
              mt="2vh"
              _hover={{ color: "gray.500" }}
            >
              Log Out
            </Box>
          </Box>
        ) : (
          <Box>
            <Box as="button" color="black" fontFamily="linotte" onClick={setShowLogInForm.on} mr="1rem" mt="2vh" _hover={{ color: "gray.500" }}>
              Log In
            </Box>
            <Button variant="gray" mr="1rem">
              Sign Up
            </Button>
          </Box>
        )}
        <AuthModal
          isOpen={showSignUpForm || showLogInForm}
          startSignUp={showSignUpForm}
          onClose={() => {
            setShowSignUpForm.off();
            setShowLogInForm.off();
          }}
        />
      </NavMenu>
    </>
  );
};

const DesktopNavContent = (props: FlexProps) => {
  const authState = useAuthState();

  const [showSignUpForm, setShowSignUpForm] = useBoolean();
  const [showLogInForm, setShowLogInForm] = useBoolean();

  return (
    <Flex className="" align="center" justify="space-between" {...props}>
      <Box rel="home">
        <Flex direction="row">
          <Image src={Logo} height="4vh" mb=".5vh" mr="1vw" mt=".4vh" />
          <Link to="/">
            <Text fontFamily="linotte" fontSize="1.7rem" fontWeight="600" mt=".3vh">
              EazyRPG
            </Text>
          </Link>
        </Flex>
      </Box>
      {authState.loggedIn ? (
        <Text fontFamily="linotte" fontSize="1.1rem">
          Welcome {authState.user}!
        </Text>
      ) : (
        <Box />
      )}
      <HStack as="ul" id="nav__primary-menu" aria-label="Main Menu" listStyleType="none" ml="0px">
        {links.map((link, idx) => {
          if (link.loggedInRequired && !authState.loggedIn) {
            return;
          }
          return (
            <Box as="li" key={idx} id={`nav__menuitem-${idx}`}>
              {link.children ? <Submenu.Desktop link={link} /> : <NavLink.Desktop onClick={() => navigate(link.href)}>{link.label}</NavLink.Desktop>}
            </Box>
          );
        })}
      </HStack>
      {authState.loggedIn ? (
        <NavLink.Desktop onClick={authState.logout}>Sign Out</NavLink.Desktop>
      ) : (
        <Box>
          <Text as="button" color="black.600" fontFamily="linotte" fontSize="1.2rem" onClick={setShowLogInForm.on} mr="1rem">
            Log In
          </Text>
          <Button variant="gray" onClick={setShowSignUpForm.on} fontSize="1.1rem">
            Sign up
          </Button>
        </Box>
      )}
      <AuthModal
        isOpen={showSignUpForm || showLogInForm}
        startSignUp={showSignUpForm}
        onClose={() => {
          setShowSignUpForm.off();
          setShowLogInForm.off();
        }}
      />
    </Flex>
  );
};

export const NavContent = {
  Mobile: MobileNavContext,
  Desktop: DesktopNavContent,
};
