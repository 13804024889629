import { Box, useColorModeValue as mode } from "@chakra-ui/react";
import * as React from "react";
import { isDesktopHook } from "../../../Hooks";
import { Hide } from "../Hide";
import { NavContent } from "./NavContent";

export function MyNavbar() {
  const isDesktop = isDesktopHook();

  return (
    <Box as="header" bg={mode("white", "gray.800")} position="relative" zIndex="10" borderBottom="1px solid black">
      <Box as="nav" aria-label="Main navigation" maxW="7xl" mx="auto" px={{ base: "6", md: "8" }}>
        <Hide hide={isDesktop}>
          <NavContent.Mobile />
        </Hide>
        <Hide hide={!isDesktop}>
          <NavContent.Desktop />
        </Hide>
      </Box>
    </Box>
  );
}
