import * as React from "react";
import { MagicBook, MagicBookMobile } from "../../../icons/MagicBook";
import { AncientScroll, AncientScrollMobile } from "../../../icons/AncientScroll";
import { Fantasy, FantasyMobile } from "../../../icons/Fantasy";
import { Knight, KnightMobile } from "../../../icons/Knight";
import { Treasure, TreasureMobile } from "../../../icons/Treasure";
import { Level, LevelMobile } from "../../../icons/Level";
import { Dragon, DragonLarge } from "../../../icons/Dragon";
import { Wizard } from "../../../icons/Wizard";

export interface Link {
  label: string;
  loggedInRequired: boolean;
  href?: string;
  children?: Array<{
    label: string;
    description?: string;
    href: string;
    icon?: React.ReactElement;
    mobileIcon?: React.ReactElement;
  }>;
}

export const links: Link[] = [
  {
    label: "Get Started",
    loggedInRequired: false,
    children: [
      {
        label: "Character Sheet",
        href: "/sheet",
        icon: <AncientScroll />,
        mobileIcon: <AncientScrollMobile />,
      },
      {
        label: "Spellbook",
        href: "/spellbook",
        icon: <MagicBook />,
        mobileIcon: <MagicBookMobile />,
      },
      {
        label: "Game Master Guide",
        href: "/master",
        icon: <Fantasy />,
        mobileIcon: <FantasyMobile />,
      },
      {
        label: "Player's Guide",
        href: "/play",
        icon: <Level />,
        mobileIcon: <LevelMobile />,
      },
      {
        label: "Starting Adventure 1",
        href: "/adventure",
        icon: <Knight />,
        mobileIcon: <KnightMobile />,
      },
      {
        label: "Starting Adventure 2",
        href: "/adventure2",
        icon: <Treasure />,
        mobileIcon: <TreasureMobile />,
      },
    ],
  },
  {
    label: "Create",
    loggedInRequired: false,
    children: [
      { label: "Monster", href: "/create/monster", icon: <DragonLarge />, mobileIcon: <Dragon /> },
      { label: "NPC", href: "/create/npc", icon: <Wizard />, mobileIcon: <Wizard /> },
    ],
  },
  {
    label: "Explore",
    loggedInRequired: false,
    children: [
      { label: "Monster", href: "/explore/monster", icon: <DragonLarge />, mobileIcon: <Dragon /> },
      { label: "NPC", href: "/explore/npc", icon: <Wizard />, mobileIcon: <Wizard /> },
    ],
  },
  {
    label: "Saved",
    loggedInRequired: true,
    children: [
      { label: "Monster", href: "/saved/monster", icon: <DragonLarge />, mobileIcon: <Dragon /> },
      { label: "NPC", href: "/saved/npc", icon: <Wizard />, mobileIcon: <Wizard /> },
    ],
  },
  // { label: "Buy", href: "https://buy.stripe.com/7sIg14bms9TxagEeUV", loggedInRequired: false },
  { label: "Retail", href: "/retail", loggedInRequired: false },
];
