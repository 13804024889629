import React, { ReactElement } from "react";

export function Level(): ReactElement {
  return (
    <svg
      id="outline"
      height="280"
      viewBox="2 0 60 100"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m35 54h-6l1-9h4z" fill="#e6e6e6" />
      <path
        d="m31.38 45h-1.38l-1 9h6l-.32-2.85a8.68 8.68 0 0 1 -1.68-.15c-2.33-1.55-2.24-3.71-1.62-6z"
        fill="#ccc"
      />
      <path
        d="m32 35a4.77 4.77 0 0 1 5 4.5v4.38a1.77 1.77 0 0 1 -1 1.54l-2 1.07a4.42 4.42 0 0 1 -4.12 0l-2-1.07a1.77 1.77 0 0 1 -1-1.54v-4.38a4.77 4.77 0 0 1 5.12-4.5z"
        fill="#e6e6e6"
      />
      <path
        d="m37 44a1.75 1.75 0 0 1 -1 1.44l-2 1.07a4.42 4.42 0 0 1 -4.12 0l-2-1.07a1.77 1.77 0 0 1 -1-1.54v-4.4a4.42 4.42 0 0 1 2.45-3.87 4.1 4.1 0 0 0 -.33 1.87v4.38a1.77 1.77 0 0 0 1 1.54l2 1.07a4.42 4.42 0 0 0 4.12 0z"
        fill="#ccc"
      />
      <path
        d="m42 61h-20l1.34-4.74a6 6 0 0 1 6-5.26l2.66 2 2.7-2a6 6 0 0 1 6 5.26z"
        fill="#f7d079"
      />
      <path
        d="m42 61h-20l1.34-4.74a6 6 0 0 1 4.66-5.12 6.07 6.07 0 0 0 -.68 2.12 3.73 3.73 0 0 0 3.61 4.74h10.22z"
        fill="#f9b15a"
      />
      <path d="m30 9h-3l5-6 5 6h-3v8h-4z" fill="#ff5f6b" />
      <path d="m31.25 3.91-4.25 5.09h3v8h4v-.91c-3.3-.56-.84-7.65-2.75-12.18z" fill="#e23348" />
      <g fill="#42210b">
        <path d="m41.93 57.09a1 1 0 0 0 -1.23-.69 1 1 0 0 0 -.7 1.23l.68 2.37h-17.36l1-3.47a.4.4 0 0 0 0-.15 5 5 0 0 1 4.68-4.38l2.4 1.8a1 1 0 0 0 1.2 0l2.4-1.8a5 5 0 0 1 3.56 1.81 1 1 0 0 0 .83.44 1 1 0 0 0 1-1 1 1 0 0 0 -.22-.63 7.06 7.06 0 0 0 -4.62-2.58l-.32-3.04 1.27-.69a2.78 2.78 0 0 0 1.5-2.44v-4.38a5.77 5.77 0 0 0 -6-5.49 5.77 5.77 0 0 0 -6 5.5v4.38a2.78 2.78 0 0 0 1.5 2.43l1.27.69-.34 3.07a7 7 0 0 0 -6.07 6l-1.36 4.66a1 1 0 0 0 .16.87 1 1 0 0 0 .8.4h20.04a1 1 0 0 0 .8-.4 1 1 0 0 0 .16-.87zm-9.93-5.34-1.61-1.19.3-2.72a5.44 5.44 0 0 0 2.62 0l.3 2.72zm-4-7.88v-4.38a3.78 3.78 0 0 1 4-3.49 3.78 3.78 0 0 1 4 3.5v4.38a.78.78 0 0 1 -.45.67l-2 1.06a3.34 3.34 0 0 1 -3.16 0l-2-1.06a.78.78 0 0 1 -.39-.68z" />
        <path d="m27 10h2v7a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-7h2a1 1 0 0 0 .77-1.64l-5-6a1 1 0 0 0 -1.54 0l-5 6a1 1 0 0 0 .77 1.64zm5-5.43 2.86 3.43h-.86a1 1 0 0 0 -1 1v7h-2v-7a1 1 0 0 0 -1-1h-.86z" />
        <path d="m7 28h-3v-5a1 1 0 0 0 -2 0v6a1 1 0 0 0 1 1h4a1 1 0 0 0 0-2z" />
        <path d="m11 30h4a1 1 0 0 0 0-2h-3v-1h2a1 1 0 0 0 0-2h-2v-1h3a1 1 0 0 0 0-2h-4a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1z" />
        <path d="m20.29 29.7a1 1 0 0 0 1.42 0l2-2a1 1 0 0 0 .29-.7v-4a1 1 0 1 0 -2 0v3.59l-1 1-1-1v-3.59a1 1 0 1 0 -2 0v4a1 1 0 0 0 .29.71z" />
        <path d="m27 30h4a1 1 0 0 0 0-2h-3v-1h2a1 1 0 0 0 0-2h-2v-1h3a1 1 0 0 0 0-2h-4a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1z" />
        <path d="m36 23a1 1 0 1 0 -2 0v6a1 1 0 0 0 1 1h4a1 1 0 0 0 0-2h-3z" />
        <path d="m53 22a1 1 0 0 0 -1 1v5h-2v-5a1 1 0 1 0 -2 0v6a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-6a1 1 0 0 0 -1-1z" />
        <path d="m61 22h-4a1 1 0 0 0 -1 1v6a1 1 0 0 0 2 0v-2h3a1 1 0 0 0 1-1v-3a1 1 0 0 0 -1-1zm-1 3h-2v-1h2z" />
        <path d="m45 41a1 1 0 0 0 0-2h-1v-1a1 1 0 0 0 -2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 1 0 2 0v-1z" />
        <path d="m52 46h-1v-1a1 1 0 0 0 -2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 0-2z" />
        <path d="m22 39v-1h1a1 1 0 0 0 0-2h-1v-1a1 1 0 0 0 -2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0z" />
        <path d="m20 51h-1v-1a1 1 0 0 0 -2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 0-2z" />
        <path d="m16 43a3 3 0 1 0 -3 3 3 3 0 0 0 3-3zm-3 1a1 1 0 1 1 1-1 1 1 0 0 1 -1 1z" />
        <path d="m48 54a3 3 0 1 0 3 3 3 3 0 0 0 -3-3zm0 4a1 1 0 1 1 1-1 1 1 0 0 1 -1 1z" />
      </g>
    </svg>
  );
}

export function LevelPage(): ReactElement {
  return (
    <svg id="outline" height="60" viewBox="0 0 64 64" width="60" xmlns="http://www.w3.org/2000/svg">
      <path d="m35 54h-6l1-9h4z" fill="#e6e6e6" />
      <path
        d="m31.38 45h-1.38l-1 9h6l-.32-2.85a8.68 8.68 0 0 1 -1.68-.15c-2.33-1.55-2.24-3.71-1.62-6z"
        fill="#ccc"
      />
      <path
        d="m32 35a4.77 4.77 0 0 1 5 4.5v4.38a1.77 1.77 0 0 1 -1 1.54l-2 1.07a4.42 4.42 0 0 1 -4.12 0l-2-1.07a1.77 1.77 0 0 1 -1-1.54v-4.38a4.77 4.77 0 0 1 5.12-4.5z"
        fill="#e6e6e6"
      />
      <path
        d="m37 44a1.75 1.75 0 0 1 -1 1.44l-2 1.07a4.42 4.42 0 0 1 -4.12 0l-2-1.07a1.77 1.77 0 0 1 -1-1.54v-4.4a4.42 4.42 0 0 1 2.45-3.87 4.1 4.1 0 0 0 -.33 1.87v4.38a1.77 1.77 0 0 0 1 1.54l2 1.07a4.42 4.42 0 0 0 4.12 0z"
        fill="#ccc"
      />
      <path
        d="m42 61h-20l1.34-4.74a6 6 0 0 1 6-5.26l2.66 2 2.7-2a6 6 0 0 1 6 5.26z"
        fill="#f7d079"
      />
      <path
        d="m42 61h-20l1.34-4.74a6 6 0 0 1 4.66-5.12 6.07 6.07 0 0 0 -.68 2.12 3.73 3.73 0 0 0 3.61 4.74h10.22z"
        fill="#f9b15a"
      />
      <path d="m30 9h-3l5-6 5 6h-3v8h-4z" fill="#ff5f6b" />
      <path d="m31.25 3.91-4.25 5.09h3v8h4v-.91c-3.3-.56-.84-7.65-2.75-12.18z" fill="#e23348" />
      <g fill="#42210b">
        <path d="m41.93 57.09a1 1 0 0 0 -1.23-.69 1 1 0 0 0 -.7 1.23l.68 2.37h-17.36l1-3.47a.4.4 0 0 0 0-.15 5 5 0 0 1 4.68-4.38l2.4 1.8a1 1 0 0 0 1.2 0l2.4-1.8a5 5 0 0 1 3.56 1.81 1 1 0 0 0 .83.44 1 1 0 0 0 1-1 1 1 0 0 0 -.22-.63 7.06 7.06 0 0 0 -4.62-2.58l-.32-3.04 1.27-.69a2.78 2.78 0 0 0 1.5-2.44v-4.38a5.77 5.77 0 0 0 -6-5.49 5.77 5.77 0 0 0 -6 5.5v4.38a2.78 2.78 0 0 0 1.5 2.43l1.27.69-.34 3.07a7 7 0 0 0 -6.07 6l-1.36 4.66a1 1 0 0 0 .16.87 1 1 0 0 0 .8.4h20.04a1 1 0 0 0 .8-.4 1 1 0 0 0 .16-.87zm-9.93-5.34-1.61-1.19.3-2.72a5.44 5.44 0 0 0 2.62 0l.3 2.72zm-4-7.88v-4.38a3.78 3.78 0 0 1 4-3.49 3.78 3.78 0 0 1 4 3.5v4.38a.78.78 0 0 1 -.45.67l-2 1.06a3.34 3.34 0 0 1 -3.16 0l-2-1.06a.78.78 0 0 1 -.39-.68z" />
        <path d="m27 10h2v7a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-7h2a1 1 0 0 0 .77-1.64l-5-6a1 1 0 0 0 -1.54 0l-5 6a1 1 0 0 0 .77 1.64zm5-5.43 2.86 3.43h-.86a1 1 0 0 0 -1 1v7h-2v-7a1 1 0 0 0 -1-1h-.86z" />
        <path d="m7 28h-3v-5a1 1 0 0 0 -2 0v6a1 1 0 0 0 1 1h4a1 1 0 0 0 0-2z" />
        <path d="m11 30h4a1 1 0 0 0 0-2h-3v-1h2a1 1 0 0 0 0-2h-2v-1h3a1 1 0 0 0 0-2h-4a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1z" />
        <path d="m20.29 29.7a1 1 0 0 0 1.42 0l2-2a1 1 0 0 0 .29-.7v-4a1 1 0 1 0 -2 0v3.59l-1 1-1-1v-3.59a1 1 0 1 0 -2 0v4a1 1 0 0 0 .29.71z" />
        <path d="m27 30h4a1 1 0 0 0 0-2h-3v-1h2a1 1 0 0 0 0-2h-2v-1h3a1 1 0 0 0 0-2h-4a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1z" />
        <path d="m36 23a1 1 0 1 0 -2 0v6a1 1 0 0 0 1 1h4a1 1 0 0 0 0-2h-3z" />
        <path d="m53 22a1 1 0 0 0 -1 1v5h-2v-5a1 1 0 1 0 -2 0v6a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-6a1 1 0 0 0 -1-1z" />
        <path d="m61 22h-4a1 1 0 0 0 -1 1v6a1 1 0 0 0 2 0v-2h3a1 1 0 0 0 1-1v-3a1 1 0 0 0 -1-1zm-1 3h-2v-1h2z" />
        <path d="m45 41a1 1 0 0 0 0-2h-1v-1a1 1 0 0 0 -2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 1 0 2 0v-1z" />
        <path d="m52 46h-1v-1a1 1 0 0 0 -2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 0-2z" />
        <path d="m22 39v-1h1a1 1 0 0 0 0-2h-1v-1a1 1 0 0 0 -2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0z" />
        <path d="m20 51h-1v-1a1 1 0 0 0 -2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 0-2z" />
        <path d="m16 43a3 3 0 1 0 -3 3 3 3 0 0 0 3-3zm-3 1a1 1 0 1 1 1-1 1 1 0 0 1 -1 1z" />
        <path d="m48 54a3 3 0 1 0 3 3 3 3 0 0 0 -3-3zm0 4a1 1 0 1 1 1-1 1 1 0 0 1 -1 1z" />
      </g>
    </svg>
  );
}

export function LevelMobile(): ReactElement {
  return (
    <svg
      id="outline"
      height="3rem"
      viewBox="2 -5 60 70"
      width="3rem"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m35 54h-6l1-9h4z" fill="#e6e6e6" />
      <path
        d="m31.38 45h-1.38l-1 9h6l-.32-2.85a8.68 8.68 0 0 1 -1.68-.15c-2.33-1.55-2.24-3.71-1.62-6z"
        fill="#ccc"
      />
      <path
        d="m32 35a4.77 4.77 0 0 1 5 4.5v4.38a1.77 1.77 0 0 1 -1 1.54l-2 1.07a4.42 4.42 0 0 1 -4.12 0l-2-1.07a1.77 1.77 0 0 1 -1-1.54v-4.38a4.77 4.77 0 0 1 5.12-4.5z"
        fill="#e6e6e6"
      />
      <path
        d="m37 44a1.75 1.75 0 0 1 -1 1.44l-2 1.07a4.42 4.42 0 0 1 -4.12 0l-2-1.07a1.77 1.77 0 0 1 -1-1.54v-4.4a4.42 4.42 0 0 1 2.45-3.87 4.1 4.1 0 0 0 -.33 1.87v4.38a1.77 1.77 0 0 0 1 1.54l2 1.07a4.42 4.42 0 0 0 4.12 0z"
        fill="#ccc"
      />
      <path
        d="m42 61h-20l1.34-4.74a6 6 0 0 1 6-5.26l2.66 2 2.7-2a6 6 0 0 1 6 5.26z"
        fill="#f7d079"
      />
      <path
        d="m42 61h-20l1.34-4.74a6 6 0 0 1 4.66-5.12 6.07 6.07 0 0 0 -.68 2.12 3.73 3.73 0 0 0 3.61 4.74h10.22z"
        fill="#f9b15a"
      />
      <path d="m30 9h-3l5-6 5 6h-3v8h-4z" fill="#ff5f6b" />
      <path d="m31.25 3.91-4.25 5.09h3v8h4v-.91c-3.3-.56-.84-7.65-2.75-12.18z" fill="#e23348" />
      <g fill="#42210b">
        <path d="m41.93 57.09a1 1 0 0 0 -1.23-.69 1 1 0 0 0 -.7 1.23l.68 2.37h-17.36l1-3.47a.4.4 0 0 0 0-.15 5 5 0 0 1 4.68-4.38l2.4 1.8a1 1 0 0 0 1.2 0l2.4-1.8a5 5 0 0 1 3.56 1.81 1 1 0 0 0 .83.44 1 1 0 0 0 1-1 1 1 0 0 0 -.22-.63 7.06 7.06 0 0 0 -4.62-2.58l-.32-3.04 1.27-.69a2.78 2.78 0 0 0 1.5-2.44v-4.38a5.77 5.77 0 0 0 -6-5.49 5.77 5.77 0 0 0 -6 5.5v4.38a2.78 2.78 0 0 0 1.5 2.43l1.27.69-.34 3.07a7 7 0 0 0 -6.07 6l-1.36 4.66a1 1 0 0 0 .16.87 1 1 0 0 0 .8.4h20.04a1 1 0 0 0 .8-.4 1 1 0 0 0 .16-.87zm-9.93-5.34-1.61-1.19.3-2.72a5.44 5.44 0 0 0 2.62 0l.3 2.72zm-4-7.88v-4.38a3.78 3.78 0 0 1 4-3.49 3.78 3.78 0 0 1 4 3.5v4.38a.78.78 0 0 1 -.45.67l-2 1.06a3.34 3.34 0 0 1 -3.16 0l-2-1.06a.78.78 0 0 1 -.39-.68z" />
        <path d="m27 10h2v7a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-7h2a1 1 0 0 0 .77-1.64l-5-6a1 1 0 0 0 -1.54 0l-5 6a1 1 0 0 0 .77 1.64zm5-5.43 2.86 3.43h-.86a1 1 0 0 0 -1 1v7h-2v-7a1 1 0 0 0 -1-1h-.86z" />
        <path d="m7 28h-3v-5a1 1 0 0 0 -2 0v6a1 1 0 0 0 1 1h4a1 1 0 0 0 0-2z" />
        <path d="m11 30h4a1 1 0 0 0 0-2h-3v-1h2a1 1 0 0 0 0-2h-2v-1h3a1 1 0 0 0 0-2h-4a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1z" />
        <path d="m20.29 29.7a1 1 0 0 0 1.42 0l2-2a1 1 0 0 0 .29-.7v-4a1 1 0 1 0 -2 0v3.59l-1 1-1-1v-3.59a1 1 0 1 0 -2 0v4a1 1 0 0 0 .29.71z" />
        <path d="m27 30h4a1 1 0 0 0 0-2h-3v-1h2a1 1 0 0 0 0-2h-2v-1h3a1 1 0 0 0 0-2h-4a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1z" />
        <path d="m36 23a1 1 0 1 0 -2 0v6a1 1 0 0 0 1 1h4a1 1 0 0 0 0-2h-3z" />
        <path d="m53 22a1 1 0 0 0 -1 1v5h-2v-5a1 1 0 1 0 -2 0v6a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-6a1 1 0 0 0 -1-1z" />
        <path d="m61 22h-4a1 1 0 0 0 -1 1v6a1 1 0 0 0 2 0v-2h3a1 1 0 0 0 1-1v-3a1 1 0 0 0 -1-1zm-1 3h-2v-1h2z" />
        <path d="m45 41a1 1 0 0 0 0-2h-1v-1a1 1 0 0 0 -2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 1 0 2 0v-1z" />
        <path d="m52 46h-1v-1a1 1 0 0 0 -2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 0-2z" />
        <path d="m22 39v-1h1a1 1 0 0 0 0-2h-1v-1a1 1 0 0 0 -2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0z" />
        <path d="m20 51h-1v-1a1 1 0 0 0 -2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 0-2z" />
        <path d="m16 43a3 3 0 1 0 -3 3 3 3 0 0 0 3-3zm-3 1a1 1 0 1 1 1-1 1 1 0 0 1 -1 1z" />
        <path d="m48 54a3 3 0 1 0 3 3 3 3 0 0 0 -3-3zm0 4a1 1 0 1 1 1-1 1 1 0 0 1 -1 1z" />
      </g>
    </svg>
  );
}
