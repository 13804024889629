import React, { ReactElement } from "react";

export function MagicBook(): ReactElement {
  return (
    <svg
      id="spell-enchant"
      enableBackground="new 0 0 300 300"
      height="50"
      viewBox="0 80 300 300"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="142.001" cy="150.002" fill="#ffe9c7" r="92" />
        <g>
          <path d="m138.001 155.178 16-21.176 16 21.176-16 18.824z" fill="#fc8c29" />
          <path
            d="m154.001 178.002c-1.172 0-2.289-.516-3.047-1.41l-16-18.824c-1.215-1.428-1.273-3.506-.145-5.002l16-21.176c1.516-2 4.867-2 6.383 0l16 21.176c1.129 1.496 1.07 3.574-.145 5.002l-16 18.824c-.757.894-1.875 1.41-3.046 1.41zm-10.875-22.971 10.875 12.795 10.875-12.795-10.875-14.395z"
            fill="#4c241d"
          />
        </g>
        <g>
          <path d="m142.001 214.002h-92v-24h104z" fill="#ffce56" />
          <path
            d="m142.001 218.002h-92c-2.211 0-4-1.791-4-4v-24c0-2.209 1.789-4 4-4h104c1.387 0 2.672.719 3.402 1.896.73 1.18.797 2.652.176 3.893l-12 24c-.68 1.355-2.063 2.211-3.578 2.211zm-88-8h85.527l8-16h-93.527z"
            fill="#4c241d"
          />
        </g>
        <g>
          <path d="m166.001 214.002h92v-24h-104z" fill="#ffce56" />
          <path
            d="m258.001 218.002h-92c-1.516 0-2.898-.855-3.578-2.211l-12-24c-.621-1.24-.555-2.713.176-3.893.73-1.178 2.016-1.896 3.402-1.896h104c2.211 0 4 1.791 4 4v24c0 2.209-1.79 4-4 4zm-89.528-8h85.527v-16h-93.527z"
            fill="#4c241d"
          />
        </g>
        <g fill="#4c241d">
          <path d="m254.001 206.002h-32c-2.211 0-4-1.791-4-4s1.789-4 4-4h32c2.211 0 4 1.791 4 4s-1.79 4-4 4z" />
          <path d="m82.001 206.002h-32c-2.211 0-4-1.791-4-4s1.789-4 4-4h32c2.211 0 4 1.791 4 4s-1.79 4-4 4z" />
          <path d="m130.001 206.002h-36c-2.211 0-4-1.791-4-4s1.789-4 4-4h36c2.211 0 4 1.791 4 4s-1.79 4-4 4z" />
          <path d="m210.001 206.002h-28c-2.211 0-4-1.791-4-4s1.789-4 4-4h28c2.211 0 4 1.791 4 4s-1.79 4-4 4z" />
          <path d="m157.997 194.002c-1.922 0-3.617-1.387-3.941-3.342-.363-2.18 1.109-4.24 3.289-4.604l96-16c2.176-.367 4.238 1.109 4.602 3.287.363 2.18-1.109 4.24-3.289 4.604l-96 16c-.224.037-.443.055-.661.055z" />
          <path d="m158.001 194.004c-1.633 0-3.168-1.01-3.758-2.635-.758-2.076.316-4.371 2.391-5.127l88-32c2.086-.752 4.371.316 5.125 2.393.758 2.076-.316 4.371-2.391 5.127l-88 32c-.453.164-.914.242-1.367.242z" />
          <path d="m158.004 194.002c-1.293 0-2.562-.625-3.332-1.781-1.227-1.838-.73-4.322 1.109-5.547l72-48c1.844-1.23 4.324-.729 5.547 1.109 1.227 1.838.73 4.322-1.109 5.547l-72 48c-.683.455-1.453.672-2.215.672z" />
          <path d="m150.004 194.002c-.219 0-.438-.018-.66-.055l-96-16c-2.18-.363-3.652-2.424-3.289-4.604.359-2.178 2.43-3.656 4.602-3.287l96 16c2.18.363 3.652 2.424 3.289 4.604-.324 1.955-2.02 3.342-3.942 3.342z" />
          <path d="m150.001 194.004c-.453 0-.914-.078-1.367-.242l-88-32c-2.074-.756-3.148-3.051-2.391-5.127.754-2.074 3.047-3.143 5.125-2.393l88 32c2.074.756 3.148 3.051 2.391 5.127-.59 1.625-2.126 2.635-3.758 2.635z" />
          <path d="m149.997 194.002c-.762 0-1.531-.217-2.215-.672l-72-48c-1.84-1.225-2.336-3.709-1.109-5.547 1.219-1.838 3.699-2.34 5.547-1.109l72 48c1.84 1.225 2.336 3.709 1.109 5.547-.77 1.156-2.039 1.781-3.332 1.781z" />
        </g>
        <g>
          <path d="m42.001 214.002h224v16h-224z" fill="#bf7e68" />
          <path
            d="m266.001 234.002h-224c-2.211 0-4-1.791-4-4v-16c0-2.209 1.789-4 4-4h224c2.211 0 4 1.791 4 4v16c0 2.209-1.79 4-4 4zm-220-8h216v-8h-216z"
            fill="#4c241d"
          />
        </g>
        <g>
          <path d="m174.001 262.002v-32h-40v32" fill="#bf7e68" />
          <path
            d="m174.001 266.002c-2.211 0-4-1.791-4-4v-28h-32v28c0 2.209-1.789 4-4 4s-4-1.791-4-4v-32c0-2.209 1.789-4 4-4h40c2.211 0 4 1.791 4 4v32c0 2.209-1.79 4-4 4z"
            fill="#4c241d"
          />
        </g>
        <g>
          <circle cx="184.001" cy="80.002" fill="#e66353" r="6" />
          <path
            d="m184.001 90.002c-5.516 0-10-4.486-10-10s4.484-10 10-10 10 4.486 10 10-4.485 10-10 10zm0-12c-1.102 0-2 .896-2 2s.898 2 2 2 2-.896 2-2-.899-2-2-2z"
            fill="#4c241d"
          />
        </g>
        <g fill="#4c241d">
          <path d="m128.544 132.547c-1.023 0-2.047-.391-2.828-1.172l-16.969-16.971c-1.562-1.562-1.562-4.094 0-5.656s4.094-1.562 5.656 0l16.969 16.971c1.562 1.562 1.562 4.094 0 5.656-.782.781-1.805 1.172-2.828 1.172z" />
          <path d="m167.297 124.549c-.492 0-.992-.092-1.477-.285-2.055-.814-3.055-3.141-2.242-5.193l8.863-22.303c.816-2.053 3.148-3.059 5.195-2.24 2.055.816 3.055 3.141 2.242 5.193l-8.863 22.303c-.624 1.568-2.128 2.525-3.718 2.525z" />
          <path d="m191.297 64.549c-.492 0-.992-.092-1.477-.285-2.055-.814-3.055-3.141-2.242-5.193l4.859-12.234c.82-2.055 3.148-3.055 5.195-2.24 2.055.814 3.055 3.141 2.242 5.193l-4.859 12.234c-.624 1.568-2.128 2.525-3.718 2.525z" />
          <path d="m140.641 124.574c-1.586 0-3.09-.953-3.715-2.518l-8.902-22.287c-.82-2.053.18-4.379 2.23-5.199 2.047-.811 4.379.178 5.199 2.23l8.902 22.287c.82 2.053-.18 4.379-2.23 5.199-.488.194-.988.288-1.484.288z" />
          <path d="m248.376 117.807c-1.535 0-3-.889-3.66-2.383-.895-2.02.016-4.383 2.039-5.277l17.629-7.803c2.008-.896 4.383.021 5.273 2.039.895 2.02-.016 4.383-2.039 5.277l-17.629 7.803c-.524.232-1.074.344-1.613.344z" />
          <path d="m187.481 144.764c-1.535 0-3-.889-3.66-2.383-.895-2.02.016-4.383 2.035-5.277l49.707-22.004c2.023-.9 4.383.02 5.277 2.039s-.016 4.383-2.039 5.277l-49.707 22.004c-.523.232-1.074.344-1.613.344z" />
          <path d="m51.915 117.045c-.488 0-.984-.09-1.465-.279l-17.914-7.041c-2.055-.809-3.066-3.129-2.258-5.186.812-2.061 3.137-3.068 5.188-2.26l17.914 7.041c2.055.809 3.066 3.129 2.258 5.186-.622 1.576-2.13 2.539-3.723 2.539z" />
          <path d="m120.79 144.113c-.488 0-.984-.09-1.465-.279l-57.008-22.404c-2.055-.809-3.066-3.129-2.258-5.186.809-2.059 3.137-3.064 5.188-2.26l57.008 22.404c2.055.809 3.066 3.129 2.258 5.186-.618 1.576-2.13 2.539-3.723 2.539z" />
          <path d="m154.001 122.002c-2.211 0-4-1.791-4-4v-28c0-2.209 1.789-4 4-4s4 1.791 4 4v28c0 2.209-1.79 4-4 4z" />
          <path d="m154.001 82.002c-2.211 0-4-1.791-4-4v-12c0-2.209 1.789-4 4-4s4 1.791 4 4v12c0 2.209-1.79 4-4 4z" />
        </g>
        <g>
          <circle cx="154.001" cy="48.002" fill="#e66353" r="6" />
          <path
            d="m154.001 58.002c-5.516 0-10-4.486-10-10s4.484-10 10-10 10 4.486 10 10-4.485 10-10 10zm0-12c-1.102 0-2 .896-2 2s.898 2 2 2 2-.896 2-2-.899-2-2-2z"
            fill="#4c241d"
          />
        </g>
        <g>
          <circle cx="124.001" cy="80.002" fill="#e66353" r="6" />
          <path
            d="m124.001 90.002c-5.516 0-10-4.486-10-10s4.484-10 10-10 10 4.486 10 10-4.485 10-10 10zm0-12c-1.102 0-2 .896-2 2s.898 2 2 2 2-.896 2-2-.899-2-2-2z"
            fill="#4c241d"
          />
        </g>
        <path
          d="m179.458 132.547c-1.023 0-2.047-.391-2.828-1.172-1.562-1.562-1.562-4.094 0-5.656l56.543-56.545c1.562-1.562 4.094-1.562 5.656 0s1.562 4.094 0 5.656l-56.543 56.545c-.782.781-1.805 1.172-2.828 1.172z"
          fill="#4c241d"
        />
        <path
          d="m179.458 132.547c-1.023 0-2.047-.391-2.828-1.172-1.562-1.562-1.562-4.094 0-5.656l56.543-56.545c1.562-1.562 4.094-1.562 5.656 0s1.562 4.094 0 5.656l-56.543 56.545c-.782.781-1.805 1.172-2.828 1.172z"
          fill="#4c241d"
        />
        <path
          d="m246.001 66.002c-1.023 0-2.047-.391-2.828-1.172-1.562-1.563-1.562-4.094 0-5.656l4-4c1.562-1.562 4.094-1.562 5.656 0s1.562 4.094 0 5.656l-4 4c-.782.781-1.805 1.172-2.828 1.172z"
          fill="#4c241d"
        />
        <path
          d="m102.001 106.002c-1.023 0-2.047-.391-2.828-1.172l-42-42c-1.562-1.562-1.562-4.094 0-5.656s4.094-1.562 5.656 0l42 42c1.562 1.562 1.562 4.094 0 5.656-.782.781-1.805 1.172-2.828 1.172z"
          fill="#4c241d"
        />
        <path
          d="m116.184 65.762c-1.551 0-3.027-.91-3.676-2.426l-10.184-23.758c-.871-2.031.07-4.383 2.102-5.252 2.016-.877 4.379.066 5.25 2.1l10.184 23.758c.871 2.031-.07 4.383-2.102 5.252-.511.22-1.047.326-1.574.326z"
          fill="#4c241d"
        />
        <path
          d="m154.001 154.002c-2.211 0-4-1.791-4-4v-12c0-2.209 1.789-4 4-4s4 1.791 4 4v12c0 2.209-1.79 4-4 4z"
          fill="#4c241d"
        />
      </g>
    </svg>
  );
}

export function MagicBookLarge(): ReactElement {
  return (
    <svg
      id="spell-enchant"
      enableBackground="new 0 0 300 300"
      height="30"
      viewBox="0 0 300 300"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="142.001" cy="150.002" fill="#ffe9c7" r="92" />
        <g>
          <path d="m138.001 155.178 16-21.176 16 21.176-16 18.824z" fill="#fc8c29" />
          <path
            d="m154.001 178.002c-1.172 0-2.289-.516-3.047-1.41l-16-18.824c-1.215-1.428-1.273-3.506-.145-5.002l16-21.176c1.516-2 4.867-2 6.383 0l16 21.176c1.129 1.496 1.07 3.574-.145 5.002l-16 18.824c-.757.894-1.875 1.41-3.046 1.41zm-10.875-22.971 10.875 12.795 10.875-12.795-10.875-14.395z"
            fill="#4c241d"
          />
        </g>
        <g>
          <path d="m142.001 214.002h-92v-24h104z" fill="#ffce56" />
          <path
            d="m142.001 218.002h-92c-2.211 0-4-1.791-4-4v-24c0-2.209 1.789-4 4-4h104c1.387 0 2.672.719 3.402 1.896.73 1.18.797 2.652.176 3.893l-12 24c-.68 1.355-2.063 2.211-3.578 2.211zm-88-8h85.527l8-16h-93.527z"
            fill="#4c241d"
          />
        </g>
        <g>
          <path d="m166.001 214.002h92v-24h-104z" fill="#ffce56" />
          <path
            d="m258.001 218.002h-92c-1.516 0-2.898-.855-3.578-2.211l-12-24c-.621-1.24-.555-2.713.176-3.893.73-1.178 2.016-1.896 3.402-1.896h104c2.211 0 4 1.791 4 4v24c0 2.209-1.79 4-4 4zm-89.528-8h85.527v-16h-93.527z"
            fill="#4c241d"
          />
        </g>
        <g fill="#4c241d">
          <path d="m254.001 206.002h-32c-2.211 0-4-1.791-4-4s1.789-4 4-4h32c2.211 0 4 1.791 4 4s-1.79 4-4 4z" />
          <path d="m82.001 206.002h-32c-2.211 0-4-1.791-4-4s1.789-4 4-4h32c2.211 0 4 1.791 4 4s-1.79 4-4 4z" />
          <path d="m130.001 206.002h-36c-2.211 0-4-1.791-4-4s1.789-4 4-4h36c2.211 0 4 1.791 4 4s-1.79 4-4 4z" />
          <path d="m210.001 206.002h-28c-2.211 0-4-1.791-4-4s1.789-4 4-4h28c2.211 0 4 1.791 4 4s-1.79 4-4 4z" />
          <path d="m157.997 194.002c-1.922 0-3.617-1.387-3.941-3.342-.363-2.18 1.109-4.24 3.289-4.604l96-16c2.176-.367 4.238 1.109 4.602 3.287.363 2.18-1.109 4.24-3.289 4.604l-96 16c-.224.037-.443.055-.661.055z" />
          <path d="m158.001 194.004c-1.633 0-3.168-1.01-3.758-2.635-.758-2.076.316-4.371 2.391-5.127l88-32c2.086-.752 4.371.316 5.125 2.393.758 2.076-.316 4.371-2.391 5.127l-88 32c-.453.164-.914.242-1.367.242z" />
          <path d="m158.004 194.002c-1.293 0-2.562-.625-3.332-1.781-1.227-1.838-.73-4.322 1.109-5.547l72-48c1.844-1.23 4.324-.729 5.547 1.109 1.227 1.838.73 4.322-1.109 5.547l-72 48c-.683.455-1.453.672-2.215.672z" />
          <path d="m150.004 194.002c-.219 0-.438-.018-.66-.055l-96-16c-2.18-.363-3.652-2.424-3.289-4.604.359-2.178 2.43-3.656 4.602-3.287l96 16c2.18.363 3.652 2.424 3.289 4.604-.324 1.955-2.02 3.342-3.942 3.342z" />
          <path d="m150.001 194.004c-.453 0-.914-.078-1.367-.242l-88-32c-2.074-.756-3.148-3.051-2.391-5.127.754-2.074 3.047-3.143 5.125-2.393l88 32c2.074.756 3.148 3.051 2.391 5.127-.59 1.625-2.126 2.635-3.758 2.635z" />
          <path d="m149.997 194.002c-.762 0-1.531-.217-2.215-.672l-72-48c-1.84-1.225-2.336-3.709-1.109-5.547 1.219-1.838 3.699-2.34 5.547-1.109l72 48c1.84 1.225 2.336 3.709 1.109 5.547-.77 1.156-2.039 1.781-3.332 1.781z" />
        </g>
        <g>
          <path d="m42.001 214.002h224v16h-224z" fill="#bf7e68" />
          <path
            d="m266.001 234.002h-224c-2.211 0-4-1.791-4-4v-16c0-2.209 1.789-4 4-4h224c2.211 0 4 1.791 4 4v16c0 2.209-1.79 4-4 4zm-220-8h216v-8h-216z"
            fill="#4c241d"
          />
        </g>
        <g>
          <path d="m174.001 262.002v-32h-40v32" fill="#bf7e68" />
          <path
            d="m174.001 266.002c-2.211 0-4-1.791-4-4v-28h-32v28c0 2.209-1.789 4-4 4s-4-1.791-4-4v-32c0-2.209 1.789-4 4-4h40c2.211 0 4 1.791 4 4v32c0 2.209-1.79 4-4 4z"
            fill="#4c241d"
          />
        </g>
        <g>
          <circle cx="184.001" cy="80.002" fill="#e66353" r="6" />
          <path
            d="m184.001 90.002c-5.516 0-10-4.486-10-10s4.484-10 10-10 10 4.486 10 10-4.485 10-10 10zm0-12c-1.102 0-2 .896-2 2s.898 2 2 2 2-.896 2-2-.899-2-2-2z"
            fill="#4c241d"
          />
        </g>
        <g fill="#4c241d">
          <path d="m128.544 132.547c-1.023 0-2.047-.391-2.828-1.172l-16.969-16.971c-1.562-1.562-1.562-4.094 0-5.656s4.094-1.562 5.656 0l16.969 16.971c1.562 1.562 1.562 4.094 0 5.656-.782.781-1.805 1.172-2.828 1.172z" />
          <path d="m167.297 124.549c-.492 0-.992-.092-1.477-.285-2.055-.814-3.055-3.141-2.242-5.193l8.863-22.303c.816-2.053 3.148-3.059 5.195-2.24 2.055.816 3.055 3.141 2.242 5.193l-8.863 22.303c-.624 1.568-2.128 2.525-3.718 2.525z" />
          <path d="m191.297 64.549c-.492 0-.992-.092-1.477-.285-2.055-.814-3.055-3.141-2.242-5.193l4.859-12.234c.82-2.055 3.148-3.055 5.195-2.24 2.055.814 3.055 3.141 2.242 5.193l-4.859 12.234c-.624 1.568-2.128 2.525-3.718 2.525z" />
          <path d="m140.641 124.574c-1.586 0-3.09-.953-3.715-2.518l-8.902-22.287c-.82-2.053.18-4.379 2.23-5.199 2.047-.811 4.379.178 5.199 2.23l8.902 22.287c.82 2.053-.18 4.379-2.23 5.199-.488.194-.988.288-1.484.288z" />
          <path d="m248.376 117.807c-1.535 0-3-.889-3.66-2.383-.895-2.02.016-4.383 2.039-5.277l17.629-7.803c2.008-.896 4.383.021 5.273 2.039.895 2.02-.016 4.383-2.039 5.277l-17.629 7.803c-.524.232-1.074.344-1.613.344z" />
          <path d="m187.481 144.764c-1.535 0-3-.889-3.66-2.383-.895-2.02.016-4.383 2.035-5.277l49.707-22.004c2.023-.9 4.383.02 5.277 2.039s-.016 4.383-2.039 5.277l-49.707 22.004c-.523.232-1.074.344-1.613.344z" />
          <path d="m51.915 117.045c-.488 0-.984-.09-1.465-.279l-17.914-7.041c-2.055-.809-3.066-3.129-2.258-5.186.812-2.061 3.137-3.068 5.188-2.26l17.914 7.041c2.055.809 3.066 3.129 2.258 5.186-.622 1.576-2.13 2.539-3.723 2.539z" />
          <path d="m120.79 144.113c-.488 0-.984-.09-1.465-.279l-57.008-22.404c-2.055-.809-3.066-3.129-2.258-5.186.809-2.059 3.137-3.064 5.188-2.26l57.008 22.404c2.055.809 3.066 3.129 2.258 5.186-.618 1.576-2.13 2.539-3.723 2.539z" />
          <path d="m154.001 122.002c-2.211 0-4-1.791-4-4v-28c0-2.209 1.789-4 4-4s4 1.791 4 4v28c0 2.209-1.79 4-4 4z" />
          <path d="m154.001 82.002c-2.211 0-4-1.791-4-4v-12c0-2.209 1.789-4 4-4s4 1.791 4 4v12c0 2.209-1.79 4-4 4z" />
        </g>
        <g>
          <circle cx="154.001" cy="48.002" fill="#e66353" r="6" />
          <path
            d="m154.001 58.002c-5.516 0-10-4.486-10-10s4.484-10 10-10 10 4.486 10 10-4.485 10-10 10zm0-12c-1.102 0-2 .896-2 2s.898 2 2 2 2-.896 2-2-.899-2-2-2z"
            fill="#4c241d"
          />
        </g>
        <g>
          <circle cx="124.001" cy="80.002" fill="#e66353" r="6" />
          <path
            d="m124.001 90.002c-5.516 0-10-4.486-10-10s4.484-10 10-10 10 4.486 10 10-4.485 10-10 10zm0-12c-1.102 0-2 .896-2 2s.898 2 2 2 2-.896 2-2-.899-2-2-2z"
            fill="#4c241d"
          />
        </g>
        <path
          d="m179.458 132.547c-1.023 0-2.047-.391-2.828-1.172-1.562-1.562-1.562-4.094 0-5.656l56.543-56.545c1.562-1.562 4.094-1.562 5.656 0s1.562 4.094 0 5.656l-56.543 56.545c-.782.781-1.805 1.172-2.828 1.172z"
          fill="#4c241d"
        />
        <path
          d="m179.458 132.547c-1.023 0-2.047-.391-2.828-1.172-1.562-1.562-1.562-4.094 0-5.656l56.543-56.545c1.562-1.562 4.094-1.562 5.656 0s1.562 4.094 0 5.656l-56.543 56.545c-.782.781-1.805 1.172-2.828 1.172z"
          fill="#4c241d"
        />
        <path
          d="m246.001 66.002c-1.023 0-2.047-.391-2.828-1.172-1.562-1.563-1.562-4.094 0-5.656l4-4c1.562-1.562 4.094-1.562 5.656 0s1.562 4.094 0 5.656l-4 4c-.782.781-1.805 1.172-2.828 1.172z"
          fill="#4c241d"
        />
        <path
          d="m102.001 106.002c-1.023 0-2.047-.391-2.828-1.172l-42-42c-1.562-1.562-1.562-4.094 0-5.656s4.094-1.562 5.656 0l42 42c1.562 1.562 1.562 4.094 0 5.656-.782.781-1.805 1.172-2.828 1.172z"
          fill="#4c241d"
        />
        <path
          d="m116.184 65.762c-1.551 0-3.027-.91-3.676-2.426l-10.184-23.758c-.871-2.031.07-4.383 2.102-5.252 2.016-.877 4.379.066 5.25 2.1l10.184 23.758c.871 2.031-.07 4.383-2.102 5.252-.511.22-1.047.326-1.574.326z"
          fill="#4c241d"
        />
        <path
          d="m154.001 154.002c-2.211 0-4-1.791-4-4v-12c0-2.209 1.789-4 4-4s4 1.791 4 4v12c0 2.209-1.79 4-4 4z"
          fill="#4c241d"
        />
      </g>
    </svg>
  );
}

export function MagicBookMobile(): ReactElement {
  return (
    <svg
      id="spell-enchant"
      enableBackground="new 0 0 300 300"
      height="3.2rem"
      viewBox="0 0 300 300"
      width="3.2rem"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="142.001" cy="150.002" fill="#ffe9c7" r="92" />
        <g>
          <path d="m138.001 155.178 16-21.176 16 21.176-16 18.824z" fill="#fc8c29" />
          <path
            d="m154.001 178.002c-1.172 0-2.289-.516-3.047-1.41l-16-18.824c-1.215-1.428-1.273-3.506-.145-5.002l16-21.176c1.516-2 4.867-2 6.383 0l16 21.176c1.129 1.496 1.07 3.574-.145 5.002l-16 18.824c-.757.894-1.875 1.41-3.046 1.41zm-10.875-22.971 10.875 12.795 10.875-12.795-10.875-14.395z"
            fill="#4c241d"
          />
        </g>
        <g>
          <path d="m142.001 214.002h-92v-24h104z" fill="#ffce56" />
          <path
            d="m142.001 218.002h-92c-2.211 0-4-1.791-4-4v-24c0-2.209 1.789-4 4-4h104c1.387 0 2.672.719 3.402 1.896.73 1.18.797 2.652.176 3.893l-12 24c-.68 1.355-2.063 2.211-3.578 2.211zm-88-8h85.527l8-16h-93.527z"
            fill="#4c241d"
          />
        </g>
        <g>
          <path d="m166.001 214.002h92v-24h-104z" fill="#ffce56" />
          <path
            d="m258.001 218.002h-92c-1.516 0-2.898-.855-3.578-2.211l-12-24c-.621-1.24-.555-2.713.176-3.893.73-1.178 2.016-1.896 3.402-1.896h104c2.211 0 4 1.791 4 4v24c0 2.209-1.79 4-4 4zm-89.528-8h85.527v-16h-93.527z"
            fill="#4c241d"
          />
        </g>
        <g fill="#4c241d">
          <path d="m254.001 206.002h-32c-2.211 0-4-1.791-4-4s1.789-4 4-4h32c2.211 0 4 1.791 4 4s-1.79 4-4 4z" />
          <path d="m82.001 206.002h-32c-2.211 0-4-1.791-4-4s1.789-4 4-4h32c2.211 0 4 1.791 4 4s-1.79 4-4 4z" />
          <path d="m130.001 206.002h-36c-2.211 0-4-1.791-4-4s1.789-4 4-4h36c2.211 0 4 1.791 4 4s-1.79 4-4 4z" />
          <path d="m210.001 206.002h-28c-2.211 0-4-1.791-4-4s1.789-4 4-4h28c2.211 0 4 1.791 4 4s-1.79 4-4 4z" />
          <path d="m157.997 194.002c-1.922 0-3.617-1.387-3.941-3.342-.363-2.18 1.109-4.24 3.289-4.604l96-16c2.176-.367 4.238 1.109 4.602 3.287.363 2.18-1.109 4.24-3.289 4.604l-96 16c-.224.037-.443.055-.661.055z" />
          <path d="m158.001 194.004c-1.633 0-3.168-1.01-3.758-2.635-.758-2.076.316-4.371 2.391-5.127l88-32c2.086-.752 4.371.316 5.125 2.393.758 2.076-.316 4.371-2.391 5.127l-88 32c-.453.164-.914.242-1.367.242z" />
          <path d="m158.004 194.002c-1.293 0-2.562-.625-3.332-1.781-1.227-1.838-.73-4.322 1.109-5.547l72-48c1.844-1.23 4.324-.729 5.547 1.109 1.227 1.838.73 4.322-1.109 5.547l-72 48c-.683.455-1.453.672-2.215.672z" />
          <path d="m150.004 194.002c-.219 0-.438-.018-.66-.055l-96-16c-2.18-.363-3.652-2.424-3.289-4.604.359-2.178 2.43-3.656 4.602-3.287l96 16c2.18.363 3.652 2.424 3.289 4.604-.324 1.955-2.02 3.342-3.942 3.342z" />
          <path d="m150.001 194.004c-.453 0-.914-.078-1.367-.242l-88-32c-2.074-.756-3.148-3.051-2.391-5.127.754-2.074 3.047-3.143 5.125-2.393l88 32c2.074.756 3.148 3.051 2.391 5.127-.59 1.625-2.126 2.635-3.758 2.635z" />
          <path d="m149.997 194.002c-.762 0-1.531-.217-2.215-.672l-72-48c-1.84-1.225-2.336-3.709-1.109-5.547 1.219-1.838 3.699-2.34 5.547-1.109l72 48c1.84 1.225 2.336 3.709 1.109 5.547-.77 1.156-2.039 1.781-3.332 1.781z" />
        </g>
        <g>
          <path d="m42.001 214.002h224v16h-224z" fill="#bf7e68" />
          <path
            d="m266.001 234.002h-224c-2.211 0-4-1.791-4-4v-16c0-2.209 1.789-4 4-4h224c2.211 0 4 1.791 4 4v16c0 2.209-1.79 4-4 4zm-220-8h216v-8h-216z"
            fill="#4c241d"
          />
        </g>
        <g>
          <path d="m174.001 262.002v-32h-40v32" fill="#bf7e68" />
          <path
            d="m174.001 266.002c-2.211 0-4-1.791-4-4v-28h-32v28c0 2.209-1.789 4-4 4s-4-1.791-4-4v-32c0-2.209 1.789-4 4-4h40c2.211 0 4 1.791 4 4v32c0 2.209-1.79 4-4 4z"
            fill="#4c241d"
          />
        </g>
        <g>
          <circle cx="184.001" cy="80.002" fill="#e66353" r="6" />
          <path
            d="m184.001 90.002c-5.516 0-10-4.486-10-10s4.484-10 10-10 10 4.486 10 10-4.485 10-10 10zm0-12c-1.102 0-2 .896-2 2s.898 2 2 2 2-.896 2-2-.899-2-2-2z"
            fill="#4c241d"
          />
        </g>
        <g fill="#4c241d">
          <path d="m128.544 132.547c-1.023 0-2.047-.391-2.828-1.172l-16.969-16.971c-1.562-1.562-1.562-4.094 0-5.656s4.094-1.562 5.656 0l16.969 16.971c1.562 1.562 1.562 4.094 0 5.656-.782.781-1.805 1.172-2.828 1.172z" />
          <path d="m167.297 124.549c-.492 0-.992-.092-1.477-.285-2.055-.814-3.055-3.141-2.242-5.193l8.863-22.303c.816-2.053 3.148-3.059 5.195-2.24 2.055.816 3.055 3.141 2.242 5.193l-8.863 22.303c-.624 1.568-2.128 2.525-3.718 2.525z" />
          <path d="m191.297 64.549c-.492 0-.992-.092-1.477-.285-2.055-.814-3.055-3.141-2.242-5.193l4.859-12.234c.82-2.055 3.148-3.055 5.195-2.24 2.055.814 3.055 3.141 2.242 5.193l-4.859 12.234c-.624 1.568-2.128 2.525-3.718 2.525z" />
          <path d="m140.641 124.574c-1.586 0-3.09-.953-3.715-2.518l-8.902-22.287c-.82-2.053.18-4.379 2.23-5.199 2.047-.811 4.379.178 5.199 2.23l8.902 22.287c.82 2.053-.18 4.379-2.23 5.199-.488.194-.988.288-1.484.288z" />
          <path d="m248.376 117.807c-1.535 0-3-.889-3.66-2.383-.895-2.02.016-4.383 2.039-5.277l17.629-7.803c2.008-.896 4.383.021 5.273 2.039.895 2.02-.016 4.383-2.039 5.277l-17.629 7.803c-.524.232-1.074.344-1.613.344z" />
          <path d="m187.481 144.764c-1.535 0-3-.889-3.66-2.383-.895-2.02.016-4.383 2.035-5.277l49.707-22.004c2.023-.9 4.383.02 5.277 2.039s-.016 4.383-2.039 5.277l-49.707 22.004c-.523.232-1.074.344-1.613.344z" />
          <path d="m51.915 117.045c-.488 0-.984-.09-1.465-.279l-17.914-7.041c-2.055-.809-3.066-3.129-2.258-5.186.812-2.061 3.137-3.068 5.188-2.26l17.914 7.041c2.055.809 3.066 3.129 2.258 5.186-.622 1.576-2.13 2.539-3.723 2.539z" />
          <path d="m120.79 144.113c-.488 0-.984-.09-1.465-.279l-57.008-22.404c-2.055-.809-3.066-3.129-2.258-5.186.809-2.059 3.137-3.064 5.188-2.26l57.008 22.404c2.055.809 3.066 3.129 2.258 5.186-.618 1.576-2.13 2.539-3.723 2.539z" />
          <path d="m154.001 122.002c-2.211 0-4-1.791-4-4v-28c0-2.209 1.789-4 4-4s4 1.791 4 4v28c0 2.209-1.79 4-4 4z" />
          <path d="m154.001 82.002c-2.211 0-4-1.791-4-4v-12c0-2.209 1.789-4 4-4s4 1.791 4 4v12c0 2.209-1.79 4-4 4z" />
        </g>
        <g>
          <circle cx="154.001" cy="48.002" fill="#e66353" r="6" />
          <path
            d="m154.001 58.002c-5.516 0-10-4.486-10-10s4.484-10 10-10 10 4.486 10 10-4.485 10-10 10zm0-12c-1.102 0-2 .896-2 2s.898 2 2 2 2-.896 2-2-.899-2-2-2z"
            fill="#4c241d"
          />
        </g>
        <g>
          <circle cx="124.001" cy="80.002" fill="#e66353" r="6" />
          <path
            d="m124.001 90.002c-5.516 0-10-4.486-10-10s4.484-10 10-10 10 4.486 10 10-4.485 10-10 10zm0-12c-1.102 0-2 .896-2 2s.898 2 2 2 2-.896 2-2-.899-2-2-2z"
            fill="#4c241d"
          />
        </g>
        <path
          d="m179.458 132.547c-1.023 0-2.047-.391-2.828-1.172-1.562-1.562-1.562-4.094 0-5.656l56.543-56.545c1.562-1.562 4.094-1.562 5.656 0s1.562 4.094 0 5.656l-56.543 56.545c-.782.781-1.805 1.172-2.828 1.172z"
          fill="#4c241d"
        />
        <path
          d="m179.458 132.547c-1.023 0-2.047-.391-2.828-1.172-1.562-1.562-1.562-4.094 0-5.656l56.543-56.545c1.562-1.562 4.094-1.562 5.656 0s1.562 4.094 0 5.656l-56.543 56.545c-.782.781-1.805 1.172-2.828 1.172z"
          fill="#4c241d"
        />
        <path
          d="m246.001 66.002c-1.023 0-2.047-.391-2.828-1.172-1.562-1.563-1.562-4.094 0-5.656l4-4c1.562-1.562 4.094-1.562 5.656 0s1.562 4.094 0 5.656l-4 4c-.782.781-1.805 1.172-2.828 1.172z"
          fill="#4c241d"
        />
        <path
          d="m102.001 106.002c-1.023 0-2.047-.391-2.828-1.172l-42-42c-1.562-1.562-1.562-4.094 0-5.656s4.094-1.562 5.656 0l42 42c1.562 1.562 1.562 4.094 0 5.656-.782.781-1.805 1.172-2.828 1.172z"
          fill="#4c241d"
        />
        <path
          d="m116.184 65.762c-1.551 0-3.027-.91-3.676-2.426l-10.184-23.758c-.871-2.031.07-4.383 2.102-5.252 2.016-.877 4.379.066 5.25 2.1l10.184 23.758c.871 2.031-.07 4.383-2.102 5.252-.511.22-1.047.326-1.574.326z"
          fill="#4c241d"
        />
        <path
          d="m154.001 154.002c-2.211 0-4-1.791-4-4v-12c0-2.209 1.789-4 4-4s4 1.791 4 4v12c0 2.209-1.79 4-4 4z"
          fill="#4c241d"
        />
      </g>
    </svg>
  );
}
