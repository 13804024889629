import { Text, HTMLChakraProps, useColorModeValue as mode } from "@chakra-ui/react";
import * as React from "react";

interface NavLinkProps extends HTMLChakraProps<"a"> {
  active?: boolean;
  onClick: () => void;
}

const DesktopNavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  const { active, onClick, ...rest } = props;
  return (
    <Text
      display="inline-block"
      onClick={onClick}
      px="4"
      py="6"
      aria-current={active ? "page" : undefined}
      fontFamily="linotte"
      color="black"
      transition="all 0.2s"
      fontSize="1.1rem"
      {...rest}
      _hover={{ color: "gray.500" }}
      _active={{ color: "gray.500" }}
      _activeLink={{ color: "gray.500" }}
    />
  );
});
DesktopNavLink.displayName = "DesktopNavLink";

export const MobileNavLink = (props: NavLinkProps) => {
  const { active, onClick, ...rest } = props;
  return (
    <Text
      onClick={onClick}
      aria-current={active ? "page" : undefined}
      display="flex"
      alignItems="center"
      w="full"
      height="14"
      fontFamily="linotte"
      color="black"
      _hover={{ color: "gray.500" }}
      _active={{ color: "gray.500" }}
      _activeLink={{ color: "gray.500" }}
      {...rest}
    />
  );
};

export const NavLink = {
  Mobile: MobileNavLink,
  Desktop: DesktopNavLink,
};
